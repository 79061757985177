import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/billings'

export default {
  getAllStatus (type = 'all') {
    return request(`${url}/status/all/${type}`)
  },
  getBillingInfo (code) {
    return request(`${url}/info/${code}`)
  },
  getSummaryInfo (code, filter) {
    return request(`${url}/summary/${code}${queryString.stringify({ filter: JSON.stringify(filter)})} `)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listJobsByPage (page, size, filter, sorting, text) {
    return request(`${url}/list-jobs/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  }
}
