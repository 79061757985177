import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment-timezone'
import { Permissions, TaskPriority } from '../../../constants'
import { taskService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import { auth, formatter, validator, exportFile } from '../../../util'

// UI
import './styles.css'
import { ControlLabel, List, Loading, Pager, Panel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const { RangePicker } = DatePicker

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const pageSize = 10

export class ClientTask extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      currentPageSize: pageSize,
      filter: {},
      filterParam: 'open',
      isGenerating: false,
      list: { list: [], total: 0 },
      loading: false,
      loadingNext: false,
      searching: false,
      searchText: '',
      showDateRangePicker: false,
      // sort: { completed_order: 1, priority_order: -1, due_date: -1 }
      sort: {}
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount() {
    const { currentPage, filter, filterParam, searchText, sort, subFilter } = this.state
    this.fetchTasks({ currentPage, filter, filterParam, searchText, sort, subFilter })
  }

  componentWillReceiveProps(nextProps) {
    const { currentPage, filter, filterParam, searchText, sort, subFilter } = this.state
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchTasks({ currentPage, filter, filterParam, searchText, sort, subFilter }, nextProps.clientId)
      }
    }
  }

  render() {
    const { clientRefId } = this.props
    const { currentPage, currentPageSize, filterParam, isGenerating, list, loading, loadingNext, searching, showDateRangePicker } = this.state
    const jobColumns = [
      {
        title: 'ID',
        width: 2,
        render: ({ id_numbering, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{formatter.capitalize(id_numbering, false)}</div>
      },
      {
        title: 'Created At',
        width: 2,
        render: ({ created_at, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{formatter.toShortDate(created_at)}<br />{formatter.toShortTime(created_at)}</div>
      },
      {
        title: 'Job Date',
        width: 2,
        render: ({ job_date, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{formatter.toShortDate(job_date)}</div>
      },
      {
        title: 'Job Duration',
        width: 2,
        render: ({ job_duration, job_duration_hrs, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>
          <div>{`${formatter.toDayHourText(job_duration)}`}</div>
          <div>{`(${formatter.toFloatDecimal(job_duration_hrs)} hr${parseFloat(job_duration_hrs) === 1 ? '' : 's'})`}</div>
        </div>
      },
      {
        title: 'Support Coordinator',
        width: 3,
        render: ({ employee_ref_id, employee_fullname, is_cancelled }) => <a href={`/employees/${employee_ref_id}/info`} rel='noopener noreferrer' target='_blank'><div className={is_cancelled ? 'disabled' : ''}>{employee_fullname}</div></a>
      },
      {
        title: 'Service',
        width: 3,
        render: ({ service_name, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{service_name}</div>
      },
      {
        title: 'Summary',
        width: 4.5,
        render: ({ summary, is_cancelled }) => <div style={{ fontSize: '9px' }} className={is_cancelled ? 'disabled' : ''}>{summary}</div>
      },
      {
        title: 'Job Status',
        width: 3,
        render: ({ claim_status_name, claim_status_color }) => {
          return (
            <div className='job-status' style={{ backgroundColor: claim_status_color || '#1d2c47' }}>
              {claim_status_name}
            </div>
          )
        }
      },
      {
        title: 'Payroll?',
        width: 1,
        render: ({ is_payroll_updated }) => is_payroll_updated
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#BBB', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: '',
        width: 1,
        render: (item) => {
          return (<div className='action-buttons'>
            {!item.budget_id
              ? <div style={{ fontSize: '16px', color: '#FF0000' }}>
                <Tooltip mouseLeaveDelay={0} title='No active plan is available. This job will not be listed in Billings.'>
                  <Icon type='warning' theme='filled' />
                </Tooltip>
              </div>
              : null}
            {this.hasAccess(Permissions.TASKJOB.INFO.READ)
              ? <Link to={`/tasks/${item.task_ref_id}/info?sid=${item.ref_id}`}>
                <div style={{ color: '#D66E00' }}>
                  <Tooltip mouseLeaveDelay={0} title='Manage job under task'>
                    <Icon type='form' />
                  </Tooltip>
                </div>
              </Link>
              : <div style={{ width: '12px' }} />}
          </div>)
        }
      }
    ]

    return (
      <Panel
        title={'Tasks'}
        subtitle={!(loading || loadingNext)
          ? (
            <div className="client-task-export-container">
              <div className="button-container">
                {isGenerating
                  ? <div className='btn'><Icon type='loading' /></div>
                  : <div className='btn' onClick={this.toggleDateRangePicker}>Export</div>}

                {this.hasAccess(Permissions.TASKJOB.INFO.CREATE) ? (
                  isGenerating
                    ? <div className='btn'><Icon type='loading' /></div>
                    : <Link to={`/tasks/add?cid=${clientRefId || ''}`}><div className='btn'>Add Task</div></Link>
                ) : null}
              </div>

              {showDateRangePicker && (
                <div className="range-picker-container">
                  <RangePicker
                    format='YYYY-MM-DD'
                    open={showDateRangePicker}
                    onChange={this.handleExport}
                  />
                </div>
              )}
            </div>
          ) : null
        }
      >
        <Row gutter={16}>
          <Col lg={8}>
            <ControlLabel>Title, Summary</ControlLabel>
            <SearchInput placeholder='Search' onChange={this.onSearchName} isSearching={searching} />
          </Col>
          <Col lg={10}>
            <ControlLabel>Task Status</ControlLabel>
            <Radio.Group onChange={this.filterTask} value={filterParam}>
              <Radio.Button value='open'>Open</Radio.Button>
              <Radio.Button value='completed'>Completed</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Skeleton loading={loading} active>
          <Loading loading={loadingNext} blur>
            <div className='client-task-list'>
              {list.list.map((item, index) => {
                const {
                  id,
                  id_numbering: idNumbering,
                  ref_id: refId,
                  is_completed: isCompleted = false,
                  is_expand: isExpand = false,
                  created_at: createdAt,
                  due_date: dueDate,
                  due_remaining_mins: remainingMins,
                  funder_fullname: funderName,
                  priority,
                  current_status_name: statusName,
                  current_status_color: statusColor,
                  job_duration_sum: jobDurationSum = 0,
                  jobs = [],
                  title,
                  task
                } = item

                const currentPriority = TaskPriority.find(e => e.value === priority)
                const priorityColor = currentPriority ? currentPriority.color : '#1D2C47'
                const isOverDue = remainingMins !== null && !(remainingMins > 0)
                return (
                  <div className={`list-item ${!isCompleted && isOverDue ? `overdue` : ''}`}>
                    <Row gutter={12}>
                      <Col lg={23}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={2}>
                            <div className='name title'>{idNumbering ? idNumbering.toUpperCase() : `TID-${refId}`}</div>
                          </Col>
                          <Col lg={6}>
                            <Row>
                              <Col lg={9}>
                                <div className='subtitle'>Created At</div>
                              </Col>
                              <Col lg={12}>
                                <div className='name date'>{formatter.toShortDate(createdAt)}</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Due At</div>
                              </Col>
                              <Col lg={18}>
                                <div className={`name date ${!isCompleted && isOverDue ? 'text-overdue' : ''}`}>{
                                  dueDate
                                    ? <div>
                                      <div>{formatter.toShortDate(dueDate)}</div>
                                      <div>{
                                        isCompleted
                                          ? `Completed`
                                          : !isOverDue
                                            ? `(${formatter.toDayHourText(remainingMins)} to due)`
                                            : '(Overdued)'}
                                      </div>
                                    </div>
                                    : 'N/A'
                                }</div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5}>
                            <Col lg={6}>
                              <div className='subtitle'>Funder</div>
                            </Col>
                            <Col lg={16}>
                              <div className='name'>{funderName}</div>
                            </Col>
                          </Col>
                          <Col lg={5}>
                            <Row gutter={8}>
                              <Col lg={6}>
                                <div className='status' style={{ marginTop: '1px', backgroundColor: priorityColor || '#1d2c47' }}>
                                  {priority}
                                </div>
                              </Col>
                              <Col lg={18}>
                                <div className='status' style={{ marginTop: '1px', backgroundColor: statusColor || '#1d2c47' }}>
                                  {statusName}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} className='action-icon'>
                        {this.hasAccess(Permissions.TASKJOB.INFO.READ)
                          ? <Link to={`/tasks/${refId}/info`}>
                            <div style={{ color: '#D66E00' }}>
                              <Tooltip mouseLeaveDelay={0} title='Manage task'>
                                <Icon type='form' />
                              </Tooltip>
                            </div>
                          </Link>
                          : null}
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                      <Col lg={23}>
                        <Row>
                          <Col lg={3}>
                            <div className='subtitle'>Title</div>
                          </Col>
                          <Col lg={19}>
                            <div className='name'>{title}</div>
                          </Col>
                          <Col lg={2}>
                            {this.hasAccess(Permissions.TASKJOB.INFO.CREATE) && !(loading || loadingNext)
                              ? <Link to={`/tasks/${refId}/info?sid=add`}>
                                <div className='btn'>
                                  Add Job
                                </div>
                              </Link>
                              : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ paddingBottom: '8px' }}>
                      <Col lg={23}>
                        <Row>
                          <Col lg={3}>
                            <div className='left-align'>
                              <div className='subtitle'>Jobs</div>
                              {validator.isNotEmptyArray(jobs)
                                ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleJobExpand(index)}>
                                  <Icon type={isExpand ? 'up-circle' : 'down-circle'} style={{ fontSize: '12pt', marginLeft: '20pt', color: '#444' }} />
                                </div>
                                : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            {validator.isNotEmptyArray(jobs)
                              ? <div className='message'>
                                {`${formatter.toDayHourText(jobDurationSum)} used in total.`}
                              </div>
                              : null}
                          </Col>
                          <Col lg={15} />
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      {validator.isNotEmptyArray(jobs)
                        ? (isExpand
                          ? <div className='task-job-list'>
                            <List cols={jobColumns} rows={jobs} />
                          </div>
                          : <div className='message' style={{ marginLeft: '15px' }}>
                            {`${jobs.length === 1 ? `1 Job ` : `${jobs.length} Jobs `}available.`}
                          </div>)
                        : <div className='message' style={{ marginLeft: '15px' }}>No jobs added yet.</div>}
                    </Row>
                  </div>
                )
              })}

              <Pager
                size={currentPageSize}
                total={list.total}
                totalText={`Total ${list.total} Task${list.total === 1 ? '' : 's'}`}
                current={currentPage}
                onChange={this.changePage}
                style={{ marginTop: '15px' }}
              />
            </div>
          </Loading>
        </Skeleton>
      </Panel>
    )
  }

  changePage = (currentPage) => {
    const { filter, filterParam, searchText, sort, subFilter } = this.state
    this.fetchTasks({ currentPage, filter, searchText, sort, filterParam, subFilter })
  }

  onSearchName = (value) => {
    const { filter, sort } = this.state
    const word = typeof value === 'string' && value.trim().length > 0 ? value.trim() : null
    const currentPageSize = !validator.isNullOrUndefined(word) ? Number.MAX_SAFE_INTEGER : pageSize
    this.setState({ currentPage: 1, currentPageSize, searchText: word, searching: true }, () => {
      this.fetchTasks({ currentPage: 1, currentPageSize, filter, searchText: word, sort }).finally(() => {
        this.setState({ searching: false })
      })
    })
  }

  fetchTasks = async ({ loading = true, currentPage = 1, currentPageSize = pageSize, filter = {}, sort = {}, searchText, filterParam }, cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.TASKS.LIST)) return

    const clientId = cid || this.props.clientId
    this.setState({ currentPage, loading: currentPage !== 1 ? false : true, loadingNext: currentPage === 1 ? false : true })

    if (!clientId) return

    if (filterParam === 'completed') {
      filter.current_status_name = { condition: '=', value: 'Completed' }
    } else if (filterParam === 'open') {
      filter.current_status_name = { condition: '<>', value: 'Completed' }
    }

    filter.client_id = clientId

    const r = await taskService.listByPage(currentPage, currentPageSize, filter, sort, searchText)

    if (r && r.list) {
      this.setState({ list: r, loading: false, loadingNext: false })
    } else {
      this.setState({ loading: false, loadingNext: false })
    }

    this.scrollToTop()
  }

  handleExport = async (date) => {
    this.setState({ showDateRangePicker: false, isGenerating: true })

    if (validator.isNotEmptyArray(date) && date.length >= 2) {
      const data = {
        start_date: date[0],
        end_date: date[1],
        client_id: this.props.clientId
      }

      const r = await exportFile.fetchExport('task', data)
    } else {
      notify.error('Unable to export tasks', 'Invalid date range selected.')
    }

    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 2000)
  }

  filterTask = (e) => {
    const { filter, loading, searchText, sort, subFilter } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue, total: 0 }, () => {
      this.fetchTasks({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue, subFilter })
    })
  }

  filterTask = (e) => {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue, total: 0 }, () => {
      this.fetchTasks({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
    })
  }

  handleJobExpand = (index) => {
    const { list } = this.state

    if (index < list.list.length) {
      list.list[index].is_expand = !list.list[index].is_expand
    }

    this.setState({ list })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  toggleDateRangePicker = () => {
    this.setState({ showDateRangePicker: !this.state.showDateRangePicker })
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientTask))
