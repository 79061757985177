/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, funderService, settingGeneralService, logService, scRateService } from '../../../services'
import { fetchingFunders, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates } from '../../../constants'
import { formatter, log, trigger, validator, uploader } from '../../../util'
import Moment from 'moment-timezone'
import { cloneDeep, isEqual } from 'lodash'

// UI
import { CustomIdentifierList, Loading, List, Page, Panel, FileTypeUpload } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import ActivityLog from '../ActivityLog'

import './styles.css'
import { apiHostname } from '../../../config'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const funderModule = 'funder'

const TabList = [
  { tabId: 1, path: '/info' },
  // { tabId: 3, path: '/custom-identifier' },
  { tabId: 2, path: '/logs' }
]

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 19 }
}
const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const longItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 18, md: 18, lg: 16 }
}

const specialFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 11 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

const isNDISOnlyMode = true


export class Funder extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      item: {},
      itemOri: {},
      loading: false,
      fundingTypeList: [],
      rateList: [],
      latitude: null,
      longitude: null,
      showSave: false,
      showEdit: true,
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      currentSubTab: '1',
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    this.fetchSettings()
    if (this.isEdit()) {
      this.fetchFunder()
    }

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { match, history } = this.props
    const { currentTab, currentSubTab, item, loading, showSave, showEdit, showFundManagerTab } = this.state
    const funderId = this.getId()
    const isInfoTab = currentTab === '1' && currentSubTab === '1'
    const isItem = item && item.id

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Funder (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.fullname} (View Only)` : showSave ? `${item.fullname} (Edit Mode)` : 'Funder'}>
            {/* {
              this.isEdit() && this.hasAccess('deleteFunder') ? showSave ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null : null} */}
            {
              showEdit && this.isEdit() && isItem && this.hasAccess(Permissions.FUNDER.INFO.UPDATE) && isInfoTab
                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              ((!this.isEdit() && this.hasAccess(Permissions.FUNDER.INFO.CREATE)) ||
              (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.FUNDER.INFO.UPDATE)))
              && isInfoTab
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  Save
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='funder-panel'>
            { this.isEdit() && this.hasAccess(Permissions.FUNDER.INFO.READ)
              ? <div className={`funder-panel-header ${item && item.active === false ? 'disabled' : ''}`}>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='funder-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={7}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-label'>Name</div>
                      <div className='funder-panel-header-value'>{item.fullname}<div className='funder-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                    {/* <Col lg={5}>
                      <div className='funder-panel-header-label'>ABN</div>
                      <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{formatter.toABNFormat(item.abn) || '-'}</div>
                    </Col>
                    <Col lg={7}>
                      <div className='funder-panel-header-label'>Suburbs</div>
                      <div className='funder-panel-header-value'>{item.preference && item.preference.suburbs_text ? formatter.capitalize(item.preference.suburbs_text, false) : '-'}</div>
                    </Col> */}
                  </Row> }
              </div> : null
            }
            <div className='funder-panel-body'>
              <Tabs
                defaultActiveKey={currentTab}
                onChange={this.handleTabChange}
              >
                <TabPane tab='Funder Info' key='1'>
                  <Tabs
                    type='card'
                    defaultActiveKey={currentSubTab}
                    onChange={this.handleSubTabChange}
                  >
                    <TabPane tab='Info' key='1'>
                      { this.infoTab() }
                    </TabPane>
                    { this.isEdit() && this.hasAccess(Permissions.FUNDER.INFO.READ)
                      ? <TabPane tab='Custom Identifier' key='2'>
                        <CustomIdentifierList key={`cftab${currentTab}`} genreId={funderId} genre={'funder'} history={this.props.history} permission={Permissions.FUNDER.INFO.UPDATE} />
                      </TabPane>
                      : null }
                  </Tabs>
                </TabPane>
                { this.isEdit() && this.hasAccess(Permissions.FUNDER.INFO.READ)
                  ? <TabPane tab='Activity Log' key='2'>
                    <ActivityLog key={`actab${currentTab}`} funderId={funderId} history={this.props.history} />
                  </TabPane>
                  : null }
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { newAccRef, funding, item, loading, fundingTypeList, rateList } = this.state
    const { getFieldDecorator } = form

    const isEdit = this.isEdit()

    return <div><Form>
      <Loading loading={loading} blur>
        <Panel title='Funding Settings'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Funding Type'>
                {getFieldDecorator('sc_funding_type_id', {
                  initialValue: item.sc_funding_type_id || null,
                  rules: [
                    { required: true, message: 'Please select funding type.' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => this.filterOptions(input, option)}>
                    {
                      fundingTypeList.map((p) => {
                        return <Option key={`ftypls-${p.id}`} value={p.id}>{p.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active !== undefined ? item.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    disabled={isNDISOnlyMode}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Rate Set'>
                {getFieldDecorator('sc_rate_id', {
                  initialValue: item.sc_rate_id || null,
                  rules: [
                    { required: true, message: 'Please select rate set.' }
                  ]
                })(
                  <Select
                    disabled={isNDISOnlyMode}
                    showSearch
                    filterOption={(input, option) => this.filterOptions(input, option)}>
                    {
                      rateList.map((p) => {
                        return <Option key={`rtcpls-${p.id}`} value={p.id}>{p.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>
        <Panel title='Funder Information'>
          <Row>
            <Col lg={12}>
              { isEdit
                ? <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                  {getFieldDecorator('acc_ref', {
                    initialValue: item.acc_ref
                  })(
                    <Input readOnly />
                  )}
                </FormItem>
                : null }
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='ABN' hasFeedback extra='Enter ABN without spacing'>
                {getFieldDecorator('abn', {
                  initialValue: item.abn || null,
                  rules: [
                    { required: false, message: ' ' },
                    { validator: this.validateABN }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row> */}

          <FormItem {...formItemLayout} label='Name' hasFeedback>
            {getFieldDecorator('fullname', {
              initialValue: item.fullname || null,
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter first name' },
                { whitespace: true, message: 'Please enter first name' }
              ]
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || null,
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || null
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={`state${states.value}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || null,
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || null,
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          {/* <FormItem {...formItemLayout} label='Language Other Than English' hasFeedback>
            {getFieldDecorator('preference.languages', {
              initialValue: item.preference && validator.isNotEmptyArray(item.preference.languages) ? item.preference.languages : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                { languageList.map((sc) => {
                    return <Option key={`lang${sc.id}`} value={sc.id}>{sc.name}</Option>
                  }) }
              </Select>
          )}
          </FormItem>

          <FormItem {...formItemLayout} label='Services Provided' hasFeedback>
            {getFieldDecorator('preference.services_provider', {
              initialValue: item.preference && validator.isNotEmptyArray(item.preference.services_provider) ? item.preference.services_provider : [],
              rules: [
                { required: true, message: 'Please select services provided.' }
              ]
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                { serviceList.map((sc) => {
                    return <Option key={`svlist${sc.id}`} value={sc.id}>{sc.name}</Option>
                  }) }
              </Select>
          )}
          </FormItem> */}

          {/* <FormItem {...formItemLayout} label='Service Suburbs' hasFeedback>
            {getFieldDecorator('preference.suburbs', {
              initialValue: item.preference && validator.isNotEmptyArray(item.preference.suburbs) ? item.preference.suburbs : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                { suburbList.map((sc) => {
                    return <Option key={`suburb${sc.id}`} value={sc.id}>{sc.name}</Option>
                  }) }
              </Select>
          )}
          </FormItem> */}
        </Panel>

        {/* <Panel title='Invoice Communication'>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Comm Email' hasFeedback>
                {getFieldDecorator('sc_inv_email', {
                  initialValue: item.sc_inv_email || null,
                  rules: [
                    { required: true, message: ' ' },
                    { validator: (r, v, c) => this.validateEmailMultiple(r, v, c, true) }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel> */}

        <Panel title='Additional Information'>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Name' hasFeedback>
                {getFieldDecorator('after_contact_name', {
                  initialValue: item.after_contact_name || null
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Number' hasFeedback>
                {getFieldDecorator('after_contact_number', {
                  initialValue: item.after_contact_number || null
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Email' hasFeedback>
                {getFieldDecorator('after_contact_email', {
                  initialValue: item.after_contact_email || null
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || null
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Panel>

      </Loading>
    </Form>
    </div>
  }

  fetchFunder = async () => {
    if (!this.hasAccess(Permissions.FUNDER.INFO.READ)) {
      return
    }

    try {
      const refId = this.getRefId()
      let id = null

      this.setState({ loading: true })
      const item = await funderService.getRef(refId)

      if (item && item.id) {
        id = item.id
        this.setState({ item, itemOri: cloneDeep(item), loading: false })
      } else {
        notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.')
    }
  }

  fetchSettings = async () => {
    const { form } = this.props
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'sc-funding-type' },
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const rates = await scRateService.listAllRates()

    if (Array.isArray(rates) && rates.length > 0) {
      form.setFieldsValue({ sc_rate_id: rates[0].id })
    }

    this.setState({
      fundingTypeList: settings && validator.isArray(settings.list) ? settings.list.filter(item => item.identifier === 'sc-funding-type') : [],
      rateList: validator.isArray(rates) ? rates : []
    })
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleSubTabChange = (index) => {
    this.setState({currentSubTab: index})
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/funders/${refId}${tab.path}`)
    }
  }

  handlePreDelete = () => {
    const { loading } = this.state
    if (loading) return

    const { handleDelete } = this
    const id = this.getId()

    confirm({
      title: 'Delete this funder?',
      content: 'THIS ACTION CANNOT UNDO! Press Ok to continue, Cancel to return',
      async onOk () {
        handleDelete(id)
      },
      onCancel () {
      }
    })
  }

  handleDelete = async (id) => {
    const { fetchingFunders, history } = this.props

    this.setState({ loading: true })

    const r = await funderService.remove(id)

    if (r && r.id) {
      notify.success('Deleted successfully', 'Funder deleted successfully.')
      log.deleteFunder(id, 'Funder is deleted.')
      window.location.replace('/funders')
      history.replace('/funders')
      fetchingFunders(true)
    } else {
      notify.error('Unable to delete successfully', 'Unable to delete funder successfully. Please try again later.')
    }

    this.setState({ loading: false })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSave = (e) => {
    e.preventDefault()
    const { loading } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    if (loading) {
      return
    }

    this.setState({ loading: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          const { fetchingFunders, history } = this.props
          const { item, latitude, longitude, loading, rateList, fundingTypeList } = this.state

          if (longitude && latitude) {
            values.longitude = longitude
            values.latitude = latitude
          }

          try {
            if (this.isEdit()) {
              const response = await funderService.save(item.id, values)

              if (response.id) {
                //--- update funder log construction starts
                const prevItem = cloneDeep(this.state.itemOri)
                const currItem = cloneDeep(values)

                const newRateSet = rateList.find(e => e.id === values.sc_rate_id)
                currItem.sc_rate_set_name = newRateSet ? newRateSet.name : null

                const newFundingType = fundingTypeList.find(e => e.id === values.sc_funding_type_id)
                currItem.sc_funding_type_name = newFundingType ? newFundingType.name : null

                log.updateFunder(
                  response.id,
                  prevItem,
                  currItem,
                  ['sc_rate_id', 'sc_funding_type_id'],
                  [
                    { key: 'sc_rate_name', label: 'Rate Set' },
                    { key: 'sc_funding_type_name', label: 'Funding Type' }
                  ]
                )
                //--- update funder log construction ends

                this.handleSaveSuccessful()
                fetchingFunders(true)
                await this.fetchFunder()
              } else {
                this.handleSaveError()
              }
            } else {
              const response = await funderService.add(values)


              if (response.id) {
                const { id, ref_id, acc_ref } = response
                log.addFunder(response.id, `New funder ${values.fullname}`)
                this.setState({ item: { ...item, ...values, id, ref_id, acc_ref } })
                this.handleSaveSuccessful()
                window.location.replace(`/funders/${ref_id}/info`)
                fetchingFunders(true)
              } else {
                this.handleSaveError()
              }
            }

            this.props.setRefreshActivityLog(true)
          } catch (e) {
            this.handleSaveError()
          }
        }

        this.setState({ loading: false })
      })
    })


  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Funder saved successfully.')
  }

  handleSaveError = () => {
    notify.error('Unable to save successfully', 'Unable to save funder successfully. Please try again later.')
  }

  validateABN = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback()
    } else if (!validator.isDigit(value) || value.length !== 11) {
      callback(new Error('ABN is invalid in format'))
    } else {
      callback()
    }
  }

  validateEmailMultiple = (rule, value, callback, isRequired = false) => {
    const MSG_VALID_EMAIL = 'Please provide a valid email'
    const MSG_ENTER_EMAIL = 'Please enter email'

    if (value === null || value === undefined || value === '' || value.trim() === '') {
      if (isRequired) {
        callback(new Error(MSG_ENTER_EMAIL))
      } else {
        callback()
      }
    } else if (value && value.length > 0 ) {
      if (!validator.isEmailMultiple(value)) {
        callback(new Error(MSG_VALID_EMAIL))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  getPreferenceChange = (prevPref, currPref, fields = [], fieldsName = []) => {
    let changeText = ''
    const { settings } = this.state

    if (fields.length === fieldsName.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const prev = prevPref[field]
        const curr = currPref[field]

        if (!isEqual(prev, curr)) {
          let prevText = []
          let currText = []
          if (validator.isArray(prev)) {
            for (let j = 0; j < prev.length; j++) {
              const s = settings.find(e => e.id === prev[j])
              if (s && s.id) {
                prevText.push(s.name)
              }
            }
          }

          if (validator.isArray(curr)) {
            for (let j = 0; j < curr.length; j++) {
              const s = settings.find(e => e.id === curr[j])
              if (s && s.id) {
                currText.push(s.name)
              }
            }
          }

          changeText += `${changeText ? ', ' : ''}${fieldsName[i]} from "${prevText.join(', ')}" to "${currText.join(', ')}"`
        }
      }
    }

    return changeText
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingFunders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Funder))
