import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportTypeService } from '../../../services'
import { auth, exportFile, formatter, validator } from '../../../util'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import { Permissions, ReportType } from '../../../constants'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import '../styles.css'

const title = `Participants' Plan Expiry`
const DefaultSort = { end_day_count: 1 }

class ReportClientPlanExpiry extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: { list: [], total: 0 },
      isGenerating: false,
      isLoaded: false,
      loading: false,
      loadingList: false,
      sort: Object.assign({}, DefaultSort),
      view: ReportType.SC_CLIENT_PLAN_ENDED
    }
  }

  componentDidMount () {
    this.fetchReport()
  }

  render () {
    const { currentPage, isGenerating, list, loading, loadingList, view } = this.state

    const endedColumns = [
      {
        title: 'Participant',
        width: 5,
        key: 'client_fullname',
        render: ({ client_ref_id, client_fullname }) => <a href={`/participants/${client_ref_id}/funding-plan`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Balance Hours',
        width: 2,
        key: 'remaining_hrs',
        render: ({ is_tbd, remaining_hrs }) => {
          return (is_tbd
            ? <div className='disabled-text'>TBC</div>
            : <div>
              <div>{formatter.toFloatDecimal(remaining_hrs)}</div>
            </div>)
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Ended On',
        width: 2,
        key: 'period_end_date',
        render: ({ period_end_date }) => <div>{ formatter.toShortDate(period_end_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'As Of Today',
        width: 2,
        key: 'end_day_count',
        render: ({ end_day_count }) => {
          const dayCount = formatter.toFloatDecimal(Math.abs(end_day_count))

          return (
            <div>{ `${dayCount === 0 ? 'TODAY' : `${dayCount} day${dayCount === 1 ? '' : 's'}`}` }</div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
    ]

    const endingColumns = [
      {
        title: 'Participant',
        width: 5,
        key: 'client_fullname',
        render: ({ client_ref_id, client_fullname }) => <a href={`/participants/${client_ref_id}/funding-plan`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Balance Hours',
        width: 2,
        key: 'remaining_hrs',
        render: ({ is_tbd, remaining_hrs }) => {
          return (is_tbd
            ? <div className='disabled-text'>TBC</div>
            : <div>
              <div>{formatter.toFloatDecimal(remaining_hrs)}</div>
            </div>)
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Ending On',
        width: 2,
        key: 'period_end_date',
        render: ({ period_end_date }) => <div>{ formatter.toShortDate(period_end_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Remaining',
        width: 2,
        key: 'end_day_count',
        render: ({ end_day_count }) => {
          const dayCount = formatter.toFloatDecimal(Math.abs(end_day_count))

          return (
            <div>{ `${dayCount === 0 ? 'TODAY' : `${dayCount} day${dayCount === 1 ? '' : 's'} to go`}` }</div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>


        <Page.Content full>
          <Page.Header title={title}>
            { this.hasAccess(Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.export()}>
                EXPORT
              </Button>
              : null }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={12}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group disabled={loading || loadingList} defaultValue={view} onChange={(v) => this.onChangeView(v)}>
                  <Radio.Button value={ReportType.SC_CLIENT_PLAN_ENDED}> Ended Bookings </Radio.Button>
                  <Radio.Button value={ReportType.SC_CLIENT_PLAN_ENDING}> Ending Bookings </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div style={{marginTop: '24px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                { view === ReportType.SC_CLIENT_PLAN_ENDED
                  ? <div className='report'>
                    <div className='report-title'>{`Participants with Ended Plan `} <span className='report-tag'>{list.total}</span></div>
                    <List cols={endedColumns} rows={list.list} />

                    <Pager
                      size={list.total}
                      total={list.total}
                      totalText={`Total ${list.total} Record${list.total === 1 ? '' : 's'}`}
                      current={currentPage}
                      onChange={(e) => this.onChangePage(e)}
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                  : view === ReportType.SC_CLIENT_PLAN_ENDING
                    ? <div className='report'>
                      <div className='report-title'>{`Participants with Ending Plan `} <span className='report-tag'>{list.total}</span></div>
                      <List cols={endingColumns} rows={list.list} />

                      <Pager
                        size={list.total}
                        total={list.total}
                        totalText={`Total ${list.total} Record${list.total === 1 ? '' : 's'}`}
                        current={currentPage}
                        onChange={(e) => this.onChangePage(e)}
                        style={{ marginTop: '15px' }}
                      />
                    </div>
                    : <div className='overmax-text'>Invalid Report Type.</div>
                  }

              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  onChangePage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchReport()
    })
  }

  onChangeView = (e) => {
    this.setState({ view: e.target.value, isLoaded: false, loading: true, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  fetchReport = () => {
    const { currentPage, filter, isLoaded, loading, sort, view } = this.state
    this.fetchClientReport({ currentPage, filter, isLoaded, loading, sort, view })
  }

  fetchClientReport = async ({ currentPage, filter, isLoaded = true, loading, sort, view }) => {
    if (this.hasAccess(Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST)) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const list = await reportTypeService.listReport(view, filter, sort)

        if (list) {
          this.setState({
            list,
            isLoaded: true,
            loadingList: false,
            loading: false
          })
        } else {
          notify.error(`Unable to get ${title} Report`, 'Please try again later.')
        }
      } catch (e) {
        notify.error(`Unable to get ${title} Report`, 'Please try again later.')
      }

      if (window) window.scrollTo({ top: 0 })
    }
  }

  handleSort = (sort, statusType) => {
    for (var s in sort) {
      if (sort[s] === 0) {
        delete sort[s]
      }
    }

    if (!validator.isNotEmptyObject(sort)) {
      sort = Object.assign({}, DefaultSort)
    }

    this.setState({ sort, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  export = async () => {
    try {
      this.setState({ isGenerating: true })

      const r = await exportFile.fetchReportExport(ReportType.SC_CLIENT_PLAN_EXPIRY)
      setTimeout(() => {
        this.setState({ isGenerating: false })
      }, 2000)
    } catch (e) {
      this.setState({ isGenerating: false })
      notify.error(`Unable to download ${title} Report`, 'Please try again later.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

export default ReportClientPlanExpiry
