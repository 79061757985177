import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

import { apiHostname } from '../../../config'
import { BillingListType, BillingMarginStateType, BillingUpdateType, FileUploadMsg, FundingManagedTypes, Permissions, ReportRequestType } from '../../../constants'
import { billingService, clientService, employeeService, reportSchedulerService, settingGeneralService, settingOtherService } from '../../../services'
import { auth, common, exportFile, formatter, validator } from '../../../util'

// UI
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'

import notify from '../../../components/Notification'
import { Button, Checkbox, Loading, List, Page, Pager } from '../../../components'
import StatusUpdateModal from '../StatusUpdateModal'

import './styles.css'

const pageSize = 20
const urlTaskRedirect = '/tasks'
const dateFormat = 'DD/MM/YYYY'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { TextArea } = Input
const Option = Select.Option
const { Group: RadioGroup, Button: RadioButton } = Radio
const { confirm } = Modal

const firstStatus = {
  reference: null,
  value: 'all',
  name: 'All'
}

const firstClient = {
  id: null,
  first_name: 'Select All Participant'
}

const firstEmployee = {
  id: null,
  fullname: 'Select All Employee'
}

const FundingManagedTypesList = [].concat({ label: 'Select All Managed Type', value: null }).concat(FundingManagedTypes.slice())

const AllSorting = { created_at: -1, client_fullname: 1, id: 1 }
const StatsSorting = { created_at: -1, client_fullname: 1, id: 1 }
// const ToRcvSorting = { id: 1, created_at: 1, job_date: 1, client_fullname: 1  }
const ToRcvSorting = { created_at: -1, client_fullname: 1, id: 1 }

const hasAccess = (accessLevel) => {
  return auth.hasAccess(accessLevel)
}

const getFilterParams = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        filter.$and.push({
          $or: [
            { id_numbering: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
            { provider_fullname: { condition: 'ilike', value: `%${words[i]}%` } }
          ]
        })
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

const getCurrentStatusList = (prevState, that = null) => {
  const { statusList, billingListType } = prevState

  let statusCurrentList = []
  if (billingListType === BillingListType.BILLING_LIST_CLAIM) {
    statusCurrentList = statusList.filter(e => e.reference_2.indexOf('_ppt') > -1).concat([firstStatus])
  } else if (billingListType === BillingListType.BILLING_LIST_PAYROLL) {
    statusCurrentList = statusList.filter(e => e.reference_2.indexOf('_emp') > -1).concat([firstStatus])
  } else {
    statusCurrentList = statusList
  }

  if (that) {
    that.setState({ statusCurrentList })
  }

  return { statusCurrentList }
}

const checkItemSelect = (selectedJobs, list, that) => {
  let isSelectedAll = null

  if (list && validator.isArray(list)) {
    for (let i = 0; i < list.length; i++) {
      const itm = list[i]

      if (selectedJobs.findIndex(e => e.id === itm.id) === -1) {
        isSelectedAll = false
        break
      }
    }

    if (isSelectedAll !== false) {
      isSelectedAll = true
    }
  } else {
    isSelectedAll = false
  }

  if (that) {
    that.setState({ isSelectedAll: !!isSelectedAll })
  }

  return { isSelectedAll }
}

export class BillingManage extends Component {
  constructor(props) {
    super(props)
    const { match, location } = this.props
    const { key = undefined } = location
    this.state = {
      currentPage: 1,
      list: [],
      total: 0,
      sort: Object.assign({}, StatsSorting),
      filter: {},
      actionButtons: [],
      billingInfo: {},
      billingListType: null,
      billingPeriodCode: null,
      currentTS: Date.now(),
      columns: [],
      fileList: [],
      fileUploadedList: {},
      rcvAmtUpdates: [],
      isListItem: undefined,
      isLoaded: false,
      isSearching: false,
      isSelectedAll: false,
      isShowDateRangePicker: false,
      isShowImportModal: false,
      loadingList: false,
      loadingListLoaded: false,
      loadingData: true,
      loadingDataSelect: false,
      loadingSummary: false,
      loadingFilterSummary: false,
      loadingSummit: false,
      isGenerating: false,
      clientList: [],
      employeeList: [],
      statusList: [],
      statusCurrentList: [],
      searchText: null,
      selectedStatus: firstStatus.reference,
      selectedJobs: [],
      selectedNotesInput: [],
      selectedBudgetManagedType: undefined,
      selectedClientId: undefined,
      selectedClientIds: [],
      selectedEmployeeId: undefined,
      selectedEmployeeIds: [],
      selectedProcessAt: null,
      selectedInvDate: null,
      settingOther: {},
      summary: {},
      filterSummary: {},
      showStatusUpdateModal: false,
      statusUpdateAction: null,
      statusUpdateModalJobList: [],
      uploadErrorMsg: '',
      currentTS: Date.now(),
      pageKey: key,
      getBillingListType: this.getBillingListType.bind(this)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location, match } = nextProps
    const { type = BillingListType.BILLING_LIST_CLAIM, code } = common.getPath(match)
    const { page, q, status } = common.getQueryStringSearchParams(location.search)
    const filter = getFilterParams(prevState.filter, q)

    // reset the selected inv list if current inv list type diff with prev one
    let selectedJobs = prevState.selectedJobs
    let rcvAmtUpdates = prevState.rcvAmtUpdates
    if (prevState.billingListType !== type) {
      selectedJobs = []
      rcvAmtUpdates = []
    }

    // if detect different billing type, 1. refetch the job list 2. refetch summary 3. reset status list with correct listing
    let statusCurrentList = prevState.statusCurrentList
    let selectedStatus = status ? status : prevState.selectedStatus
    let sort = prevState.sort

    // if (prevState.billingListType !== null && prevState.billingListType !== type) {
    //   const { statusCurrentList: scl } = getCurrentStatusList({...prevState, billingListType: type})
    //   statusCurrentList = scl

    //    // --- enable if wanna force reset status to the first status if switch between Claim/Payroll
    //   selectedStatus = validator.isNotEmptyArray(statusCurrentList) ? statusCurrentList[0].value : firstStatus.value

    //   // set sort if detected selectedStatus === 'all' or ''
    //   sort = selectedStatus === 'all'
    //     ? Object.assign({}, AllSorting)
    //     : selectedStatus === 'to-receive'
    //       ? Object.assign({}, ToRcvSorting)
    //       : Object.assign({}, StatsSorting)

    //   // reset the selected job list if upcoming status is different with prev one
    //   if (selectedStatus !== prevState.selectedStatus) {
    //     selectedJobs = []
    //   }

    //   filter.status = { condition: '=', value: selectedStatus }
    // } else if (prevState.billingListType === null && type) {
    //   // required to set the correct sorting when invListType is null. or else the sorting is wrong when refreshing when status === ALL (default is Stats Sorting)
    //   sort = selectedStatus === 'all'
    //     ? Object.assign({}, AllSorting)
    //     : selectedStatus === 'to-receive'
    //       ? Object.assign({}, ToRcvSorting)
    //       : Object.assign({}, StatsSorting)
    // }


    let actionButtons = prevState.actionButtons
    let columns = prevState.columns

    if (selectedStatus !== prevState.selectedStatus) {
      const data = prevState.getBillingListType(type, selectedStatus)
      actionButtons = data.actionButtons
      columns = data.columns
    }

    const state = {
      ...prevState,
      actionButtons,
      billingListType: type,
      billingPeriodCode: code,
      columns,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage,
      filter,
      pageKey: location.key,
      rcvAmtUpdates,
      searchText: q || '',
      sort,
      statusCurrentList,
      selectedJobs,
      selectedStatus
    }

    return state
  }

  componentDidMount() {
    this.fetchDataList()
  }

  getTitle() {
    const { billingListType } = this.state

    if (billingListType === BillingListType.BILLING_LIST_CLAIM) {
      return 'Claim'
    } else if (billingListType === BillingListType.BILLING_LIST_PAYROLL) {
      return 'Payroll'
    }

    return 'Billings'
  }

  render() {
    const {
      form,
      history
    } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const {
      actionButtons,
      currentPage,
      columns,
      billingInfo = {},
      billingListType,
      clientList,
      employeeList,
      fileList,
      isListItem,
      isSelectedAll,
      isShowImportModal,
      list,
      loadingData,
      loadingDataSelect,
      loadingList,
      loadingListLoaded,
      loadingSummary,
      loadingFilterSummary,
      loadingSummit,
      pageKey,
      rcvAmtUpdates,
      selectedBudgetManagedType,
      selectedClientId,
      selectedEmployeeId,
      selectedJobs,
      selectedStatus,
      settingOther,
      showStatusUpdateModal,
      statusCurrentList,
      statusUpdateAction,
      statusUpdateModalJobList,
      summary,
      filterSummary,
      filter,
      total,
      uploadErrorMsg
    } = this.state

    const totalText = billingListType === BillingListType.BILLING_LIST_CLAIM || billingListType === BillingListType.BILLING_LIST_PAYROLL
      ? `job${total === 1 ? '' : 's'}`
      : `record${total === 1 ? '' : 's'}`

    const onChangePromptInfo = {
      title: `There are receiving amounts that yet to update`,
      content: <div>
        <p>If you navigate to other page, all updated values in this page will be cleared.</p>
        <p style={{ color: 'red', fontWeight: 'bold' }}>ARE YOU SURE?</p>
      </div>
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header
            title={this.getTitle()}
            total={billingInfo && billingInfo.id ? billingInfo.name : ''}
          >
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='summary-section'>
            <Spin spinning={loadingSummary} tip={'Loading...'}>
              <Row style={{ marginTop: '10px' }}>
                <Col lg={1} className='col' />
                {billingListType === BillingListType.BILLING_LIST_CLAIM
                  ? <Col lg={3} className='col'>
                    <div className='number'>{summary.sum_client || 0}</div>
                    Participants
                  </Col>
                  : billingListType === BillingListType.BILLING_LIST_PAYROLL
                    ? <Col lg={3} className='col'>
                      <div className='number'>{summary.sum_employee || 0}</div>
                      Support Coordinators
                    </Col>
                    : null}
                <Col lg={3} className='col'>
                  <div className='number'>{summary.sum_job || 0}&nbsp;{`(${summary.sum_job_duration_hrs ? formatter.toFloatDecimal(summary.sum_job_duration_hrs) : 0})`}</div>
                  Jobs
                  <div className='sum'>{`${formatter.toPrice(summary.sum_subtotal ? summary.sum_subtotal : 0)}`}</div>
                </Col>
                <Col lg={4} className='col'>
                  <div className='number'>{summary.sum_to_claim || 0}&nbsp;{`(${summary.sum_to_claim_duration_hrs ? formatter.toFloatDecimal(summary.sum_to_claim_duration_hrs) : 0})`}</div>
                  To Claim (Hours)
                  <div className='sum'>{`${formatter.toPrice(summary.sum_to_claim_subtotal ? summary.sum_to_claim_subtotal : 0)}`}</div>
                </Col>
                <Col lg={4} className='col'>
                  <div className='number'>{summary.sum_to_receive || 0}&nbsp;{`(${summary.sum_to_receive_duration_hrs ? formatter.toFloatDecimal(summary.sum_to_receive_duration_hrs) : 0})`}</div>
                  To Receive (Hours)
                  <div className='sum'>{`${formatter.toPrice(summary.sum_to_receive_subtotal ? summary.sum_to_receive_subtotal : 0)}`}</div>
                </Col>
                <Col lg={4} className='col'>
                  <div className='number'>{summary.sum_to_payroll || 0}&nbsp;{`(${summary.sum_to_payroll_duration_hrs ? formatter.toFloatDecimal(summary.sum_to_payroll_duration_hrs) : 0})`}</div>
                  To Pay (Hours)
                  <div className='sum'>{`${formatter.toPrice(summary.sum_to_payroll_subtotal ? summary.sum_to_payroll_subtotal : 0)}`}</div>
                </Col>
                <Col lg={4} className='col'>
                  <div className='number'>{summary.sum_received || 0}&nbsp;{`(${summary.sum_received_duration_hrs ? formatter.toFloatDecimal(summary.sum_received_duration_hrs) : 0})`}</div>
                  Received (Hours)
                  <div className='sum'>{`${formatter.toPrice(summary.sum_received_subtotal ? summary.sum_received_subtotal : 0)}`}</div>
                </Col>
                <Col lg={1} className='col' />
              </Row>
            </Spin>
          </div>

          <Form>
            {loadingData
              ? null
              : <div className='buttons-section' style={{ marginTop: '10px' }}>
                <Row>
                  <Col lg={16}>
                    <div className='buttons-start'>
                      <RadioGroup value={selectedStatus} onChange={this.handleChangeOption}>
                        {statusCurrentList.map(e => (
                          <RadioButton value={e.value}>{e.name}</RadioButton>
                        ))}
                      </RadioGroup>
                      {validator.isNotEmptyArray(statusCurrentList)
                        ? <div className='action-button-refresh' style={{ marginLeft: '12px' }} onClick={loadingList || loadingListLoaded ? null : () => this.onRefreshCurrentPage()}>
                          <Icon type='redo' style={{ color: '#888' }} />
                        </div>
                        : null}
                      {settingOther && settingOther.id && selectedStatus === 'to-receive'
                        ? <div style={{ marginLeft: '20px' }}>
                          <a className='notice-msg' href={`/settings/others`} rel='noopener noreferrer' target='_blank'>
                            Auto Close Margin: +/- {formatter.toPrice(settingOther.sc_auto_closed_margin)}
                          </a>
                        </div>
                        : null}
                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className='buttons-end' style={{ height: '40px' }}>
                      {actionButtons.map(e => (e))}
                    </div>
                  </Col>
                </Row>
              </div>}

            <div className='dropdown-section'>
              {billingListType === BillingListType.BILLING_LIST_CLAIM && !loadingDataSelect
                ? <Row gutter={8} style={{ marginTop: '10px' }}>
                  <Col lg={9}>
                    <FormItem>
                      {getFieldDecorator('filter_client_ref_id', {
                        initialValue: selectedClientId
                      })(
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder='Select Participant'
                          optionFilterProp='children'
                          notFoundContent='Not found'
                          filterOption={(input, option) => this.findClients(input, option)}
                          onChange={(e) => this.handleChangeSelect(e, 'selectedClientId')}
                          disabled={loadingList}
                        >
                          {clientList.map((client, idx) => {
                            return <Option key={`client${idx}`} value={client.id}>
                              <span>{client.first_name} {client.last_name} {client.ndis_number ? <span className='clientId'>({client.ndis_number})</span> : null}</span>
                            </Option>
                          })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={6}>
                    <FormItem>
                      {getFieldDecorator('filter_budget_managed_type', {
                        initialValue: selectedBudgetManagedType
                      })(
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder='Select Managed Type'
                          optionFilterProp='children'
                          notFoundContent='Not found'
                          filterOption={(input, option) => this.findEmployees(input, option)}
                          onChange={(e) => this.handleChangeSelect(e, 'selectedBudgetManagedType')}
                          disabled={loadingList}
                        >
                          {FundingManagedTypesList.map((tp, idx) => {
                            return <Option key={`mgmttype${idx}`} value={tp.value}>
                              <span>{tp.label}</span>
                            </Option>
                          })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={2} />
                  <Col lg={7}>
                    {validator.isNotEmptyArray(list) && !loadingList && !isListItem && !(selectedStatus === 'cancelled' || selectedStatus === 'all' || selectedStatus === 'rejected' || selectedStatus === 'to-receive')
                      ? <div className='buttons-end' style={{ height: '40px', marginRight: '25px' }}>
                        <div style={{ marginRight: '10px', fontSize: '11px', fontWeight: '400', color: '#333' }}>Select / Deselect All ({selectedJobs.length} item{selectedJobs.length === 1 ? '' : 's'} selected)</div>
                        <Checkbox
                          checked={isSelectedAll}
                          onClick={f => this.updateItemSelectAll(f)}
                        />
                      </div>
                      : null}
                  </Col>
                </Row>
                : billingListType === BillingListType.BILLING_LIST_PAYROLL && !loadingDataSelect
                  ? <Row gutter={8} style={{ marginTop: '10px' }}>
                    <Col lg={9}>
                      <FormItem>
                        {getFieldDecorator('filter_employee_ref_id', {
                          initialValue: selectedEmployeeId
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder='Select Employee'
                            optionFilterProp='children'
                            notFoundContent='Not found'
                            filterOption={(input, option) => this.findEmployees(input, option)}
                            onChange={(e) => this.handleChangeSelect(e, 'selectedEmployeeId')}
                            disabled={loadingList}
                          >
                            {employeeList.map((emp, idx) => {
                              return <Option key={`employee${idx}`} value={emp.id}>
                                <span>{emp.fullname}</span>
                              </Option>
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={15}>
                      {validator.isNotEmptyArray(list) && !loadingList && !isListItem && !(selectedStatus === 'cancelled' || selectedStatus === 'all' || selectedStatus === 'rejected' || selectedStatus === 'to-receive')
                        ? <div className='buttons-end' style={{ height: '40px', marginRight: '25px' }}>
                          <div style={{ marginRight: '10px', fontSize: '11px', fontWeight: '400', color: '#333' }}>Select / Deselect All ({selectedJobs.length} item{selectedJobs.length === 1 ? '' : 's'} selected)</div>
                          <Checkbox
                            checked={isSelectedAll}
                            onClick={f => this.updateItemSelectAll(f)}
                          />
                        </div>
                        : null}
                    </Col>
                  </Row>
                  : null}
            </div>
            {!loadingDataSelect
              ? <div className='summary-section filter'>
                <Spin spinning={loadingSummary || loadingListLoaded} tip={'Loading...'}>
                  <div className='content'>
                    <div className='item'>
                      {billingListType === BillingListType.BILLING_LIST_CLAIM
                        ? <>
                          <span className='label'>Participant :</span>
                          <span>{filterSummary.client_count}</span>
                        </>
                        : billingListType === BillingListType.BILLING_LIST_PAYROLL
                          ? <>
                            <span className='label'>Support Coordinator :</span>
                            <span>{filterSummary.employee_count}</span>
                          </>
                          : null}
                    </div>
                    <div className='item'>
                      <span className='label'>Jobs :</span>
                      <span>{filterSummary.jobs}</span>
                    </div>
                    <div className='item'>
                      <span className='label'>Hours :</span>
                      <span>{filterSummary.job_hrs}</span>
                    </div>
                    <div className='item'>
                      <span className='label'>Amount :</span>
                      <span>{formatter.toPrice(filterSummary.amount)}</span>
                    </div>
                    <div className='item'>
                      <span className='label'>RCV Total :</span>
                      <span>{formatter.toPrice(filterSummary.rcv_amount)}</span>
                    </div>
                    <div className='item'>
                      <span className='label'>Remaining :</span>
                      <span>{formatter.toPrice(filterSummary.rem_amount)}</span>
                    </div>
                  </div>
                </Spin>
              </div> : null}

            <div className='list-section'>
              <Skeleton loading={loadingList} active>
                <Spin spinning={loadingListLoaded} tip={'Getting Jobs...'}>
                  {/* { isListItem
                    ? list.map(e => this.getRcvRows(e))
                    : <List cols={columns} rows={list} /> } */}
                  <List cols={columns} rows={list} />

                  <Pager
                    current={currentPage}
                    size={pageSize}
                    total={total}
                    totalText={`Total ${total} ${totalText}`}
                    onChange={this.changePage}
                    style={{ marginTop: '15px' }}
                    onChangePromptInfo={onChangePromptInfo}
                    onChangePrompt={isListItem && validator.isNotEmptyArray(rcvAmtUpdates)}
                  />
                </Spin>
              </Skeleton>
            </div>

            <StatusUpdateModal
              key={pageKey}
              actionType={statusUpdateAction}
              jobList={statusUpdateModalJobList}
              listType={billingListType}
              onClose={() => this.triggerStatusUpdateModal()}
              onUpdate={this.onUpdateJobs}
              period={billingInfo}
              visible={showStatusUpdateModal}
            />

            <Modal
              width='600px'
              key={`invimportrcv`}
              visible={isShowImportModal}
              title={'Import Job Received Amount'}
              onCancel={loadingSummit ? () => { } : () => { this.triggerRcvImportUploadModal(false) }}
              footer={[
                <Loading loading={false} blur>
                  <Button
                    key='rcvclose'
                    ghost
                    feedback={loadingSummit}
                    onClick={loadingSummit ? () => { } : () => { this.triggerRcvImportUploadModal(false) }}
                  >
                    Close
                  </Button>
                  <Button
                    style={{ backgroundColor: '#3d34eb', color: '#FFF' }}
                    key='rcvconfirm'
                    ghost
                    feedback={loadingSummit}
                    onClick={() => this.handleSubmitImportRequest()}
                  >
                    Confirm
                  </Button>
                </Loading>
              ]}
            >
              <Loading loading={loadingSummary} blur>
                <Form>
                  <div className='inv-title' style={{ margin: '0px' }}>Upload the specified file for the request.</div>

                  <div className='upload-panel-content error'>{ReportRequestType.TASK_JOB_RCV_IMPORT.uploadText}</div>

                  <div style={{ marginTop: '20px' }}>
                    <Upload
                      method={'POST'}
                      action={`${apiHostname}/private/api/report/scheduler/file`}
                      name={'file'}
                      onRemove={this.fileRemove}
                      onChange={this.fileChange}
                      beforeUpload={this.fileSet}
                      headers={{ Authorization: `Bearer ${auth.getCurrentToken()}` }}
                      fileList={fileList}
                      multiple={false}
                    >
                      <Button>
                        <Icon type='upload' /> Select File
                      </Button>
                    </Upload>
                  </div>
                  {uploadErrorMsg
                    ? <div className='upload-panel-content error'>{uploadErrorMsg}</div>
                    : null}
                </Form>
              </Loading>
            </Modal>
          </Form>
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage = 1) => {
    this.fetchList({ page: currentPage })
    this.setState({ rcvAmtUpdates: [] })
  }

  fetchDataList = async () => {
    const { billingListType, billingPeriodCode } = this.state
    this.setState({ loadingList: true, loadingData: true, loadingDataSelect: true })

    const p = await billingService.getBillingInfo(billingPeriodCode)
    const s = await billingService.getAllStatus('options')
    const z = await settingOtherService.list()

    const statusList = validator.isNotEmptyArray(s) ? s.filter(e => e.reference_2 && e.reference_2.indexOf('opts') > -1) : []
    const { statusCurrentList } = getCurrentStatusList({ ...this.state, statusList })
    const selectedStatus = this.state.selectedStatus ? this.state.selectedStatus : validator.isNotEmptyArray(statusCurrentList) ? statusCurrentList[0].value : firstStatus.value
    const { columns, actionButtons } = this.getBillingListType(billingListType, selectedStatus)

    this.setState({
      actionButtons,
      billingInfo: p && p.id ? p : {},
      columns,
      settingOther: z,
      statusList,
      statusCurrentList,
      selectedStatus,
      loadingData: false
    }, async () => {
      this.fetchList({ page: 1 }, false)

      const c = await clientService.listAllClients()
      const e = await employeeService.listAllEmployees()

      const clientList = validator.isNotEmptyArray(c) ? [firstClient].concat(c) : []
      const employeeList = validator.isNotEmptyArray(e) ? [firstEmployee].concat(e) : []

      this.setState({
        clientList,
        employeeList,
        loadingDataSelect: false
      })
    })
  }

  fetchJobList = async ({ loading = true, currentPage = 1, filter = {}, selectedStatus = firstStatus.value, sort = {}, searchText }) => {
    const { billingListType, billingInfo } = this.state
    if (billingListType === BillingListType.BILLING_LIST_CLAIM && !hasAccess(Permissions.BILLINGS.CLAIM.LIST)) return
    if (billingListType === BillingListType.BILLING_LIST_PAYROLL && !hasAccess(Permissions.BILLINGS.PAYROLL.LIST)) return

    try {
      delete filter.id

      filter.period_code = { condition: '=', value: billingInfo.code }

      if (billingListType === BillingListType.BILLING_LIST_CLAIM) {
        delete filter.is_payroll_updated

        if (selectedStatus === firstStatus.value) {
          delete filter.claim_status
        } else if (selectedStatus) {
          filter.claim_status = { condition: '=', value: selectedStatus }
        } else {
          filter.id = { condition: '=', value: 0 } // let the listing load nothing if any of conditions are not matched.
        }
      } if (billingListType === BillingListType.BILLING_LIST_PAYROLL) {
        if (selectedStatus === 'to-pay') {
          filter.is_payroll_updated = { condition: '=', value: false }
        } else if (selectedStatus === firstStatus.value) {
          delete filter.is_payroll_updated
          delete filter.claim_status
        } else {
          filter.id = { condition: '=', value: 0 }
        }
      }

      const { isLoaded } = this.state

      this.setState({
        currentPage,
        filter,
        searchText,
        loadingList: isLoaded ? false : true,
        loadingListLoaded: isLoaded ? true : false
      })

      const [r] = await Promise.all([
        billingService.listJobsByPage(currentPage, pageSize, filter, sort, filter.$and ? '' : searchText),
        this.fetchJobSummary(filter)
      ])

      if (r && validator.isArray(r.list)) {
        this.setState({
          loadingList: false,
          loadingListLoaded: false,
          list: r.list || [],
          total: r.total || 0
        }, () => {
          this.updateListColumnsButtons(selectedStatus)
        })
      } else {
        notify.error('Unable to load successfully', 'Unable to load jobs successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load jobs successfully. Please try again later.')
    }
  }

  fetchJobStatusList = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'sc-job-status' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    if (settings && validator.isNotEmptyArray(settings.list)) {
      this.setState({
        statusList: settings.list.filter(item => item.identifier === 'sc-job-status')
      })
    }
  }

  fetchJobSummary = async (filter) => {
    const { billingPeriodCode } = this.state
    this.setState({ loadingSummary: true })

    const r = await billingService.getSummaryInfo(billingPeriodCode, filter)

    if (r && r.totalsummary) {
      this.setState({
        loadingSummary: false,
        summary: r.totalsummary
      })
    }

    if (r && r.filterSummary) {
      this.setState({
        loadingSummary: false,
        filterSummary: r.filterSummary
      })
    }
  }

  async fetchList({ page = 1, searchText, selectedStatus }, isRedirect = true) {
    const { filter, loading, selectedStatus: prevStatus, searchText: prevSearchText, sort } = this.state

    // if (isRedirect) {
    //   this.redirectUrl({ page, q: searchText !== undefined ? searchText : prevSearchText, status: selectedStatus || prevStatus })
    // }

    this.fetchJobList({ currentPage: page, filter, loading, selectedStatus: selectedStatus || prevStatus, searchText: searchText !== undefined ? searchText : prevSearchText, sort })
  }

  // need to readjust find functions if option layout is changed
  findClients = (input, option) => {
    // console.log('option c 1', option)
    const clientArr = option.props.children.props.children
    const clientNdis = option.props.children.props.children[option.props.children.props.children.length - 1] // ndis span block is at last position of props.children array
    // console.log('option c', clientNdis, option)
    const c = `${clientArr[0]} ${clientArr[2]} ${clientNdis && validator.isNotEmptyArray(clientNdis.props.children) ? clientNdis.props.children[1] : ''}`
    return c.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findEmployees = (input, option) => {
    // console.log('option p', option)
    const empArr = option.props.children.props.children
    const e = `${empArr}`
    return e.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getBillingListType = (billingListType, selectedStatus) => {
    let columns = []
    let actionButtons = []
    if (billingListType === BillingListType.BILLING_LIST_CLAIM) {
      columns = this.getListColumns(selectedStatus, billingListType, this)
      actionButtons = this.getActionButtons(selectedStatus, billingListType, this)
    } else if (billingListType === BillingListType.BILLING_LIST_PAYROLL) {
      columns = this.getListColumns(selectedStatus, billingListType, this)
      actionButtons = this.getActionButtons(selectedStatus, billingListType, this)
    }

    return { columns, actionButtons }
  }

  getActionButtons = (status, billingListType, that) => {
    const buttons = []
    const btnStyle = { marginLeft: '10px' }

    const toClaimButtons = (
      <Button key={`btn-${billingListType}-${status}`} style={{ ...btnStyle, backgroundColor: '#274faa' }} onClick={() => this.triggerStatusUpdateModal(true, BillingUpdateType.BLS_UPDATE_CLAIM)}>
        Generate Pymt Req & Invoice
      </Button>
    )

    const rcvButtons = (
      <div className='buttons-end'>
        {hasAccess(Permissions.BILLINGS.EXPORT.UPDATE)
          ? <Button key={`btn-${billingListType}-${status}-import-rcv`} style={{ ...btnStyle, backgroundColor: '#1d2c47' }} onClick={() => this.triggerRcvImportUploadModal(true)}>
            Import Rcv Amt
          </Button>
          : null}
        <Button key={`btn-${billingListType}-${status}-update-rcv`} style={{ ...btnStyle, backgroundColor: '#eb7a34' }} onClick={() => this.triggerStatusUpdateModalRcv(true, BillingUpdateType.BLS_UPDATE_RCV_PYMT)}>
          Update Rcv Amt
        </Button>
      </div>
    )

    const toPayButtons = (
      <Button key={`btn-${billingListType}-${status}`} style={{ ...btnStyle, backgroundColor: '#0033cc' }} onClick={() => this.triggerStatusUpdateModal(true, BillingUpdateType.BLS_UPDATE_PAYROLL)}>
        To Payroll
      </Button>
    )

    if (status === 'to-claim') {
      buttons.push(toClaimButtons)
    } else if (status === 'to-receive') {
      buttons.push(rcvButtons)
    } else if (status === 'to-pay') {
      buttons.push(toPayButtons)
    } else if (status === 'rejected') {
    } else if (status === 'cancelled') {
    } else if (status === 'all') {
    }

    return buttons
  }

  getItemSelectData = (item) => {
    const data = {
      id: item.id,
      id_numbering: item.id_numbering,
      job_date: item.job_date,
      job_duration_hrs: item.job_duration_hrs,
      claim_status: item.claim_status,
      claim_status_reference: item.claim_status_reference,
      client_fullname: item.client_fullname,
      employee_fullname: item.employee_fullname,
      subtotal: item.subtotal,
      received_subtotal: item.received_subtotal,
      created_at: item.created_at
    }

    return data
  }

  getListColumns = (status, billingListType, that) => {
    const { form } = that.props
    const { currentTS, loadingList, loadingListLoaded, selectedStatus, selectedNotesInput } = that.state
    const { getFieldValue, getFieldDecorator } = form
    let column = []

    const id = {
      title: 'Job No',
      width: 2,
      render: ({ id_numbering }) => {
        return <span>
          {formatter.capitalize(id_numbering, false)}
        </span>
      }
    }

    const client = {
      title: 'Participant',
      width: 4,
      render: ({ client_ref_id, client_fullname }) => { return <div className='sccol-name'><a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div> }
    }

    const employee = {
      title: 'Support Coordinator',
      width: 4,
      render: ({ employee_ref_id, employee_fullname }) => { return <div className='sccol-name'><a href={`/employees/${employee_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div> }
    }

    const jobCreatedAt = {
      title: 'Created At',
      width: 2,
      render: ({ created_at }) => { return <div>{formatter.toShortDate(created_at)}</div> }
    }

    const mgmt = {
      title: 'Fund Mgmt',
      width: 3,
      render: ({ budget_fund_managed_type_name }) => { return <div>{budget_fund_managed_type_name}</div> }
    }

    const jobDate = {
      title: 'Job Date',
      width: 2,
      render: ({ job_date }) => { return <div>{formatter.toShortDate(job_date)}</div> }
    }

    const jobDurationHrs = {
      title: 'Hours',
      width: 2,
      render: ({ job_duration_hrs }) => { return <div>{formatter.toFloatDecimal(job_duration_hrs)}</div> }
    }

    const jobSubtotal = {
      title: 'Amount',
      width: 2,
      render: ({ subtotal }) => { return <div>{formatter.toPrice(subtotal)}</div> }
    }

    const jobRcvSubtotal = {
      title: 'Rcv Total',
      width: 2,
      render: ({ received_subtotal }) => { return <div>{formatter.toPrice(received_subtotal)}</div> }
    }

    const jobRemainingAmt = {
      title: 'Remaining',
      width: 2,
      render: ({ subtotal, received_subtotal, is_closed }) => { return <div>{formatter.toPrice(parseFloat(subtotal) - parseFloat(received_subtotal))}</div> }
    }

    const jobTaskStatus = {
      title: 'Task Status',
      width: 4,
      render: ({ task_status_name, task_status_color }) => {
        return (
          <div className='job-status' style={{ backgroundColor: task_status_color || '#1d2c47' }}>
            {task_status_name}
          </div>
        )
      }
    }

    const jobClaimStatus = {
      title: 'Job Status',
      width: 4,
      render: ({ claim_status_name, claim_status_color }) => {
        return (
          <div className='job-status' style={{ backgroundColor: claim_status_color || '#1d2c47' }}>
            {claim_status_name}
          </div>
        )
      }
    }

    const jobIsPayrollUpdated = {
      title: 'Payroll?',
      width: 1,
      render: ({ is_payroll_updated }) => <div style={{ fontSize: '11pt' }}>
        {is_payroll_updated
          ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
          : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' />}
      </div>
    }

    const jobViewIcon = (item) => {
      return hasAccess(Permissions.PARTICIPANT.TASKS.READ)
        ? <Link to={`${urlTaskRedirect}/${item.task_ref_id}/info?sid=${item.ref_id}`}>
          <div className='button-last' style={{ color: '#D66E00' }}>
            <Tooltip mouseLeaveDelay={0} title='Manage Job'>
              <Icon type='form' />
            </Tooltip>
          </div>
        </Link>
        : null
    }

    const jobCheckButton = (item) => {
      const isChecked = that.state.selectedJobs.find(e => e.id === item.id) || false

      return hasAccess(Permissions.PARTICIPANT.TASKS.UPDATE)
        ? <div className='button-checklist' style={{ color: '#D66E00' }}>
          <Checkbox
            checked={isChecked}
            onClick={(e) => this.updateItemSelect(e, item)}
          />
        </div>
        : null
    }

    const rcvDateInput = {
      title: 'Rcv Date',
      width: 3,
      render: (itm) => {
        const { id, created_at, remaining_subtotal } = itm
        const idf = `_${id}_${currentTS}`

        return <div>
          <FormItem
            style={{ width: '120px' }}
          >
            {getFieldDecorator(`received_date${idf}`, {
              initialValue: null,
              rules: [
                { validator: (r, v, c) => this.validateRcvAmtDate(r, v, c, created_at) }
              ]
            })(
              <DatePicker
                defaultPickerValue={moment(new Date())}
                format={dateFormat}
                placeholder={''}
              />
            )}
          </FormItem>
        </div>
      }
    }

    const rcvInput = {
      title: 'Rcv Amt',
      width: 4,
      render: (itm) => {
        const { id, is_closed, created_at, remaining_subtotal } = itm
        const idf = `_${id}_${currentTS}`
        const v = getFieldValue(`received_subtotal${idf}`)
        const marginState = getFieldValue(`received_subtotal_margin${idf}`)

        return (
          <div>
            <FormItem
              style={{ width: '140px' }}
              extra={marginState === BillingMarginStateType.LESS_MARGIN
                ? <div style={{ color: '#D66E00' }}><strong>NOT full Amt!</strong></div>
                : (marginState === BillingMarginStateType.LESS_AMT || marginState === BillingMarginStateType.MORE_AMT)
                  ? <div style={{ color: '#2dcf58' }}><strong>NOT exact Amt</strong></div>
                  : null}
            >
              {getFieldDecorator(`received_subtotal${idf}`, {
                initialValue: null,
                rules: [
                  { whitespace: true, message: 'Please enter rcv amt' },
                  { validator: (r, v, c) => this.validateItemRcvAmount(r, v, c, remaining_subtotal, idf) }
                ]
              })(
                <Input
                  onChange={(e) => this.onInputChange(e, 'received_subtotal', 'is_checked', idf, remaining_subtotal)}
                  addonBefore='$'
                  disabled={is_closed || loadingList || loadingListLoaded}
                />
              )}
            </FormItem>
            <FormItem
              style={{ width: '140px', display: 'none' }}
            >
              {getFieldDecorator(`received_subtotal_margin${idf}`, {
                initialValue: null,
              })(
                <Input />
              )}
            </FormItem>
          </div>
        )
      }
    }

    const rcvNoteInput = {
      title: 'Rcv Notes',
      width: 2,
      render: (item) => {
        const { id, is_closed, created_at, remaining_subtotal } = item
        const idf = `_${id}_${currentTS}`
        const v = getFieldValue(`received_notes${idf}`)
        const isNoteInput = selectedNotesInput.find(e => e === id)

        return (
          <div>
            <div onClick={() => this.updateRcvNoteExpand(id)}>
              <Icon
                type={isNoteInput ? 'minus-circle' : 'plus-circle'}
                theme='filled'
                style={{ fontSize: '15px', color: v ? 'red' : '#333', padding: '10px 5px 6px 6px', cursor: 'pointer' }}
              />
            </div>
            <FormItem
              style={{
                display: isNoteInput ? 'inline' : 'none',
                position: 'absolute',
                backgroundColor: '#fff',
                border: '1px solid #88888833',
                borderRadius: '8px',
                padding: '9px',
                zIndex: 1000,
                width: '160px'
              }}
            >
              {getFieldDecorator(`received_notes${idf}`, {
                initialValue: null,
                rules: [
                  { whitespace: true, message: 'Please enter rcv notes' },
                  { validator: (r, v, c) => this.validateItemRcvNote(r, v, c, idf) }
                ]
              })(
                <TextArea
                  onChange={(e) => this.onInputChange(e, 'received_notes', 'is_checked', idf, remaining_subtotal)}
                  row={2}
                  disabled={is_closed || loadingList || loadingListLoaded}
                />
              )}
            </FormItem>
          </div>
        )
      }
    }

    const itemClosedSw = {
      title: 'Closed?',
      width: 1,
      render: ({ id, is_closed, created_at, remaining_subtotal }) => {
        const idf = `_${id}_${currentTS}`

        return (
          <div className='buttons'>
            <FormItem>
              {getFieldDecorator(`is_closed${idf}`, {
                initialValue: is_closed,
                valuePropName: 'checked'
              })(
                <Switch
                  disabled={is_closed}
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  onChange={(e) => this.onInputChange(e, 'is_closed', 'is_checked', idf, remaining_subtotal, is_closed)}
                />
              )}
            </FormItem>
          </div>
        )
      }
    }

    const actionsRcv = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const { id, is_closed, created_at, remaining_subtotal } = item
        const idf = `_${id}_${currentTS}`
        const isChecked = that.state.rcvAmtUpdates.find(e => e === idf) || false
        const isStatusMatched = item.claim_status === status
        const isDeleted = item.is_delete === true

        // console.log('actions rcv', that.state.rcvAmtUpdates, isChecked, idf, new Date())
        return (isDeleted
          ? null
          : <div className='buttons' key='rcv-buttons'>
            <div className='button-checklist' style={{ color: '#D66E00' }}>
              <Checkbox
                key={`checkbxc${idf}`}
                checked={!!isChecked}
                onClick={e => this.onRcvItemCheckChange(e, idf)}
                disabled={is_closed}
              />
            </div>
            {jobViewIcon(item)}
          </div>
        )
      }
    }

    const actionsAuthorise = {
      title: 'Actions',
      width: 1,
      render: (item) => {
        const isStatusMatched = billingListType === BillingListType.BILLING_LIST_CLAIM
          ? item.claim_status === status
          : billingListType === BillingListType.BILLING_LIST_PAYROLL
            ? (item.is_payroll_updated === false && status !== firstStatus.value)
            : false

        const isDeleted = item.is_delete === true

        return isDeleted
          ? null
          : <div className='buttons' key='auth-buttons'>
            {isStatusMatched ? jobCheckButton(item) : null}
            {jobViewIcon(item)}
          </div>
      }
    }

    const allColumns = [id, jobCreatedAt, mgmt, jobDate, jobDurationHrs, jobSubtotal, jobRcvSubtotal, jobClaimStatus, jobIsPayrollUpdated, actionsAuthorise]


    if (billingListType === BillingListType.BILLING_LIST_CLAIM) {
      if (selectedStatus === 'to-receive') {
        return [client, id, jobCreatedAt, mgmt, jobDate, jobDurationHrs, jobSubtotal, jobRcvSubtotal, jobRemainingAmt, rcvInput, rcvDateInput, rcvNoteInput, itemClosedSw, actionsRcv]
      } else if (selectedStatus === 'to-claim') {
        return [client, id, jobCreatedAt, mgmt, jobDate, jobDurationHrs, jobSubtotal, actionsAuthorise]
      } else {
        return [client].concat(allColumns)
      }
    } else if (billingListType === BillingListType.BILLING_LIST_PAYROLL) {
      if (selectedStatus === 'to-pay') {
        return [employee, id, jobCreatedAt, jobDate, jobDurationHrs, jobSubtotal, jobIsPayrollUpdated, actionsAuthorise]
      } else {
        return [employee, id, jobCreatedAt, jobDate, jobDurationHrs, jobSubtotal, jobRcvSubtotal, jobClaimStatus, jobIsPayrollUpdated, actionsAuthorise]
      }
    }

    return allColumns
  }

  handleChangeOption = (e) => {
    const { billingListType, searchText } = this.state
    const value = e.target.value

    this.redirectUrl({ page: 1, q: searchText !== undefined ? searchText : '', status: value }, () => {
      const newFilter = this.resetFilterOption()

      this.setState({
        filter: newFilter,
        selectedStatus: value,
        selectedJobs: [],
        isSelectedAll: false,
        sort: value === 'all'
          ? Object.assign({}, AllSorting)
          : value === 'to-receive'
            ? Object.assign({}, ToRcvSorting)
            : Object.assign({}, StatsSorting)
      }, async () => {
        this.updateListColumnsButtons(value)
        this.fetchList({ page: 1, selectedStatus: value })
      })
    })
  }

  handleChangeSelect = (e, type) => {
    if (type === 'selectedClientId' || type === 'selectedEmployeeId') {
      this.onSearchValue(e, type)
    } else if (type === 'selectedBudgetManagedType') {
      this.onSearchValue(e, type)
    }

    this.updateItemSelectAllClear()
  }

  onInputChange = (e, field, updateField, idf, maxRcvAmount, isItemDefaultClosed = false) => {
    const { form } = this.props
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form
    const value = typeof e === 'boolean' ? e : e.target.value ? e.target.value.trim() : e.target.value

    const rcvAmt = field === 'received_subtotal' ? value : getFieldValue(`received_subtotal${idf}`)
    const rcvNote = field === 'received_notes' ? value : getFieldValue(`received_notes${idf}`)
    const rcvDate = getFieldValue(`received_date${idf}`)
    const isEmptyRcvAmt = rcvAmt === null || rcvAmt === undefined || rcvAmt === ''
    const isEmptyRcvNote = rcvNote === null || rcvNote === undefined || rcvNote === ''
    const isUpdatedCheck = field === 'is_closed' && isItemDefaultClosed === false ? value : getFieldValue(`is_closed${idf}`)

    if ((!isEmptyRcvAmt || !isEmptyRcvNote)) {
      this.onRcvItemCheckChange(undefined, idf, true, true)

      if (!rcvDate) {
        setFieldsValue({ [`received_date${idf}`]: moment(new Date()) })
      }
    } else if (isUpdatedCheck) {
      if (isEmptyRcvAmt) {
        setFieldsValue({ [`received_date${idf}`]: undefined })
        setFieldsValue({ [`received_notes${idf}`]: undefined })
      }

      this.onRcvItemCheckChange(undefined, idf, true, value)
    } else {
      if (isEmptyRcvAmt) {
        setFieldsValue({ [`received_date${idf}`]: undefined })
        setFieldsValue({ [`received_notes${idf}`]: undefined })
      }

      this.onRcvItemCheckChange(undefined, idf, true, false)
    }

    if (field === 'received_subtotal') {
      const marginState = this.validateItemRcvAmountWithMargin(rcvAmt, maxRcvAmount)
      // auto trigger is closed trigger if rcv amt === max rcv amt
      if (marginState > BillingMarginStateType.LESS_MARGIN && marginState < BillingMarginStateType.MORE_MARGIN) {
        setFieldsValue({ [`is_closed${idf}`]: true })
      } else {
        setFieldsValue({ [`is_closed${idf}`]: false })
      }

      setFieldsValue({ [`received_subtotal_margin${idf}`]: marginState })
    }
  }

  onRcvItemCheckChange = (e, idf, isForcedSet = false, forcedValue) => {
    const { rcvAmtUpdates } = this.state

    const s = rcvAmtUpdates.findIndex(f => f === idf)

    if (s > -1) {
      if (!isForcedSet) {
        rcvAmtUpdates.splice(s, 1)
      } else if (isForcedSet && forcedValue === false) {
        rcvAmtUpdates.splice(s, 1)
      }
    } else {
      if (!isForcedSet) {
        rcvAmtUpdates.push(idf)
      } else if (isForcedSet && forcedValue === true) {
        rcvAmtUpdates.push(idf)
      }
    }

    this.setState({ rcvAmtUpdates })
  }

  onRefreshCurrentPage = () => {
    const { currentPage } = this.state
    this.fetchList({ page: currentPage })
    this.setState({ rcvAmtUpdates: [], selectedJobs: [] })
  }

  onSearchName (value) {
    this.setState({ isSearching: true })
    this.fetchList({ page: 1, searchText: value })
  }

  onSearchValue (value, type) {
    this.setState({ [type]: value }, () => {
      const { filter } = this.state

      if (type === 'selectedClientId') {
        if (value === null || value === '') {
          delete filter['client_id']
        } else {
          filter.client_id = { condition: '=', value: value }
        }
      } if (type === 'selectedEmployeeId') {
        if (value === null || value === '') {
          delete filter['employee_id']
        } else {
          filter.employee_id = { condition: '=', value: value }
        }
      } else if (type === 'selectedBudgetManagedType') {
        if (value === null || value === '') {
          delete filter['budget_fund_managed_type']
        } else {
          filter.budget_fund_managed_type = { condition: '=', value: value }
        }
      }

      this.fetchList({ page: 1, [type]: value })
    })
  }

  onUpdateJobs = async (jobs, file, actionType) => {
    const { list, total, currentPage, searchText, selectedStatus } = this.state

    if (selectedStatus === 'to-receive' || selectedStatus === 'to-pay') {
      // load the whole list again if it is rcv payment update or to pay
      this.setState({ rcvAmtUpdates: [], selectedJobs: [] })
      this.fetchList({ page: currentPage, searchText, selectedStatus }, false)
    } else {
      this.setState({ rcvAmtUpdates: [], selectedJobs: [] })

      if (validator.isNotEmptyArray(list)) {
        // manually update the data without loading and apply the status update changes to reducer state instead
        for (let i = 0; i < list.length; i++) {
          const job = list[i]
          const updateJob = jobs.find(e => e.id === job.id)

          if (updateJob && updateJob.id) {
            list[i].claim_status = updateJob.claim_status
            list[i].claim_status_name = updateJob.claim_status_name
            list[i].claim_status_color = updateJob.claim_status_color
            list[i].claim_status_reference = updateJob.claim_status_reference
          }
        }

        this.setState({ list, loading: false })
      }
    }

    if (file && file.id) {
      await exportFile.fetchExport(file.type, file)
    }
  }

  redirectUrl = (query, callback = () => { }) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })

    if (callback) callback()
  }

  resetFilterOption = () => {
    const { form } = this.props
    const { filter } = this.state

    delete filter['filter_budget_managed_type']
    delete filter['provider_ref_id']
    delete filter['client_ref_id']

    form.setFieldsValue({
      filter_budget_managed_type: undefined,
      filter_provider_ref_id: undefined,
      filter_client_ref_id: undefined,
    })

    this.setState({
      filter,
      selectedBudgetManagedType: undefined,
      selectedClientId: undefined,
      selectedEmployeeId: undefined
    })
  }

  updateItemSelect = (e, item) => {
    const check = e.target.checked
    let { selectedJobs, list } = this.state

    const data = this.getItemSelectData(item)

    const c = selectedJobs.findIndex(e => e.id === data.id)
    if (c > -1) {
      selectedJobs.splice(c, 1)
    } else {
      selectedJobs.push(data)
    }

    this.setState({
      selectedJobs
    }, () => {
      checkItemSelect(selectedJobs, list, this)
    })
  }

  updateItemSelectAll = (e) => {
    const check = e.target.checked

    const { selectedJobs, list } = this.state

    let newSelectList = selectedJobs.slice()

    if (list && validator.isArray(list)) {
      for (let i = 0; i < list.length; i++) {
        const itm = list[i]
        const idx = newSelectList.findIndex(e => e.id === itm.id)

        if (check) {
          if (idx > -1) {
            newSelectList.splice(idx, 1)
          }
        } else {
          if (idx === -1) {
            const data = this.getItemSelectData(itm)

            newSelectList.push(data)
          }
        }
      }

      this.setState({
        isSelectedAll: !check,
        selectedJobs: newSelectList.slice()
      })
    }
  }

  updateItemSelectAllClear = () => {
    this.setState({
      isSelectedAll: false,
      selectedJobs: []
    })
  }

  updateRcvNoteExpand = (id, value) => {
    const { selectedNotesInput } = this.state

    const c = selectedNotesInput.findIndex(e => e === id)

    if (c > -1) {
      selectedNotesInput.splice(c, 1)
    } else {
      selectedNotesInput.push(id)
    }

    this.setState({ selectedNotesInput })
  }

  updateListColumnsButtons = (selectedStatus) => {
    const { billingListType } = this.state
    const { columns, actionButtons } = this.getBillingListType(billingListType, selectedStatus)

    this.setState({
      actionButtons,
      columns
    })
  }

  validateRcvAmtDate = (rule, value, callback, jobCreatedAt) => {
    // empty rcv date at here is allowed. the api will set current date as rcv date if submitted rcv date is blank
    if (value === null || value === undefined || value === '' || value === 0) {
      callback()
    } else {
      if (jobCreatedAt) {
        const m = moment(jobCreatedAt).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Receive Date cannot be earlier than Job created date.`))
        }
      }

      callback()
    }
  }

  validateItemRcvAmount = (rule, value, callback, maxRcvAmount, idf) => {
    const { form } = this.props
    const { validateFields, setFieldsValue } = form

    if (value === null || value === undefined || value === '') {
      callback()
    } else {
      const v = validator.isCurrencyAmountExtra(value)
      if (!v) {
        callback(new Error(`Rcv Amount is not number or decimal format`))
      } else {
        const marginState = this.validateItemRcvAmountWithMargin(value, maxRcvAmount, idf)

        if (marginState === BillingMarginStateType.MORE_MARGIN) {
          callback(new Error(`Rcv Amount cannot exceed To Rcv Amount with Margin Adj`))
        } else if (value === 0) {
          callback(new Error(`Rcv Amount cannot be zero`))
        } else if (value < 0) {
          callback(new Error(`Rcv Amount cannot be less than zero`))
        } else {
          callback()
        }

        // if (parseFloat(value) === 0) {
        //   callback(new Error(`Rcv Amount cannot be zero`))
        // } else {
        //   callback()
        // }
      }
    }

    validateFields([`received_notes${idf}`])
  }

  /**
   *
   * @param {*} v1: value to be validated
   * @param {*} v2: referenced value
   * @param {*} idf item identifier
   * @returns
   */
  validateItemRcvAmountWithMargin = (v1, v2, idf) => {
    const { settingOther } = this.state
    const vv1 = formatter.toPriceFloat(v1)
    const vv2 = formatter.toPriceFloat(v2)
    const margin = formatter.toPriceFloat(settingOther.sc_auto_closed_margin || 0)

    let marginState = null

    if (vv1 < vv2) {
      if (vv1 < (vv2 - margin)) {
        marginState = BillingMarginStateType.LESS_MARGIN
      } else {
        marginState = BillingMarginStateType.LESS_AMT
      }
    } else if (vv1 > vv2) {
      if (vv1 > (vv2 + margin)) {
        marginState = BillingMarginStateType.MORE_MARGIN
      } else {
        marginState = BillingMarginStateType.MORE_AMT
      }
    } else {
      marginState = BillingMarginStateType.EQUAL
    }

    return marginState
  }

  validateItemRcvNote = (rule, value, callback, idf) => {
    const { form } = this.props
    const { getFieldValue } = form

    if (value === null || value === undefined || value === '') {
      callback()
    } else {
      const rcvAmt = getFieldValue(`received_subtotal${idf}`)
      // if rcv amt is empty but rcv notes has value, trigger error
      if (rcvAmt === null || rcvAmt === undefined || rcvAmt === '') {
        callback(new Error(`Rcv Amount cannot be zero`))
      } else {
        callback()
      }
    }
  }

  /** upload modal related */
  handleSubmitImportRequest = async () => {
    const { navigateToReportRequestPage } = this
    const { fileList, fileUploadedList } = this.state

    if (fileList.length === 0) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
    } else if (!fileUploadedList.uid) {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
    } else {
      this.setState({ loadingSummit: true })

      const body = {
        ...fileUploadedList,
        type: ReportRequestType.TASK_JOB_RCV_IMPORT.code
      }

      try {
        const r = await reportSchedulerService.createScheduleReport(body)

        if (r && r.id) {
          this.triggerRcvImportUploadModal(false)
          confirm({
            title: 'Import Received Amount Successfully',
            content: (
              <div>
                <p>Import Received Amount Request is made successfully. The process is scheduled and will be processed shortly.</p>
                <p>You could go to "Report Generate Request" section to view the progress.</p>
              </div>
            ),
            okText: 'Go to Report Request Page',
            cancelText: 'Back',
            onOk () {
              // eslint-disable-next-line no-lone-blocks
              navigateToReportRequestPage()
            },
            onCancel () {
            }
          })
        } else {
          notify.error('Unable to Import Received Amount', 'Unable to made the request. Please try again later.')
          this.triggerRcvImportUploadModal(false)
        }
      } catch (e) {
        notify.error('Unable to Import Received Amount', e && e.errors ? formatter.toErrorMessage(e.errors) : 'Unable to made the request. Please try again later.')

        this.triggerRcvImportUploadModal(false)
      }
    }
  }

  triggerRcvImportUploadModal = (isShowImportModal) => {
    this.setState({
      isShowImportModal,
      fileList: [],
      fileUploadedList: {},
      loadingSummit: false
    })
  }

  triggerStatusUpdateModal = (isUpdateModal = false, action = null, jobInfo = undefined) => {
    const { loadingList, loadingListLoaded, selectedJobs } = this.state
    if (loadingList || loadingListLoaded) return

    let statusUpdateModalJobList = []
    if (jobInfo) {
      statusUpdateModalJobList = [jobInfo]
    } else {
      statusUpdateModalJobList = selectedJobs.slice()
    }

    if (isUpdateModal) {
      if (validator.isNotEmptyArray(statusUpdateModalJobList)) {
        this.setState({
          showStatusUpdateModal: isUpdateModal,
          statusUpdateAction: action,
          statusUpdateModalJobList
        })
      } else {
        notify.error(`Unable to ${action}`, 'Please select at least one job.')
      }
    } else {
      this.setState({
        showStatusUpdateModal: isUpdateModal,
        statusUpdateAction: null,
        statusUpdateModalJobList: []
      })
    }
  }

  triggerStatusUpdateModalRcv = (isUpdateModal = false, action = null) => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { loadingList, loadingListLoaded, list, rcvAmtUpdates } = this.state
    if (loadingList || loadingListLoaded) return

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        let statusUpdateModalJobList = []

        if (validator.isNotEmptyArray(rcvAmtUpdates)) {
          for (let i = 0; i < rcvAmtUpdates.length; i++) {
            const upt = rcvAmtUpdates[i]
            const keySps = upt.split('_')

            if (validator.isNotEmptyArray(keySps)) {
              const jobId = parseInt(keySps[1])
              const job = list.find(e => e.id === jobId)

              if (job && job.id && (formatter.toPriceFloat(values[`received_subtotal${upt}`]) || values[`is_closed${upt}`] === true)) {
                const data = {
                  id: jobId,
                  update_received_subtotal: formatter.toPriceFloat(values[`received_subtotal${upt}`]),
                  update_received_notes: values[`received_notes${upt}`],
                  update_received_date: values[`received_date${upt}`],
                  update_received_margin_state: values[`received_subtotal_margin${upt}`],
                  update_is_closed: values[`is_closed${upt}`],
                  actual_received_subtotal: formatter.toPriceFloat(job.received_subtotal) + formatter.toPriceFloat(values[`received_subtotal${upt}`]),
                  id_numbering: job.id_numbering,
                  client_fullname: job.client_fullname,
                  employee_fullname: job.employee_fullname,
                  job_date: job.job_date,
                  job_duration_hrs: job.job_duration_hrs,
                  subtotal: job.subtotal,
                  rcv_subtotal: job.received_subtotal
                }

                statusUpdateModalJobList.push(data)
              }
            }
          }

          if (validator.isNotEmptyArray(statusUpdateModalJobList)) {
            this.setState({
              showStatusUpdateModal: isUpdateModal,
              statusUpdateAction: action,
              statusUpdateModalJobList
            })
          } else {
            notify.error(`Unable to update payment`, 'Please update at least one job.')
          }
        } else {
          notify.error(`Unable to update payment`, 'Please update at least one job.')
        }
      } else {
        notify.error('Unable to update payment', 'Please resolve the issue on inputs.')
      }
    })
  }

  navigateToReportRequestPage = () => {
    window.open(`/reports/custom-generate`)
  }

  /** file related */
  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({ fileList: newFileList, fileUploadedList: {} })
    }
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({ fileUploadedList: data, uploadErrorMsg: '' })
      }
    }
  }

  fileSet = (file) => {
    if (file && (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    )) {
      this.setState({ fileList: [file], uploadErrorMsg: '' })
      return true
    } else {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatCSV })
      return false
    }
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(BillingManage))
