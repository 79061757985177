import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportTypeService } from '../../../services'
import { auth, exportFile, formatter, validator } from '../../../util'

// UI
import { Button, List, Page, Pager } from '../../../components'
import { Permissions, ReportType, TaskPriority } from '../../../constants'
import notify from '../../../components/Notification'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import '../styles.css'

const title = `Task Due Report`
const DefaultSort = { due_remaining_mins: 1 }

class ReportTaskDue extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: { list: [], total: 0 },
      isGenerating: false,
      isLoaded: false,
      loading: false,
      loadingList: false,
      sort: Object.assign({}, DefaultSort)
    }
  }

  componentDidMount () {
    this.fetchReport()
  }

  render () {
    const { currentPage, isGenerating, list, loading, loadingList } = this.state

    const columns = [
      {
        title: 'Title',
        width: 5,
        key: 'title',
        render: ({ title, ref_id }) => <a href={`/tasks/${ref_id}/info`} rel='noopener noreferrer' target='_blank'>{title}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Participant',
        width: 5,
        key: 'client_fullname',
        render: ({ client_ref_id, client_fullname }) => <a href={`/participants/${client_ref_id}/funding-plan`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Due On',
        width: 3,
        key: 'due_date',
        render: ({ due_date }) => <div>{formatter.toShortDate(due_date)}</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Priority',
        width: 2,
        key: 'priority',
        render: ({ priority }) => {
          const currentPriority = TaskPriority.find(e => e.value === priority)
          const priorityColor =  currentPriority ? currentPriority.color : '#1D2C47'
          return (
            <div className='priority-label' style={{color: priorityColor, borderColor: priorityColor }}>
              { priority }
            </div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Current Status',
        width: 3,
        key: 'due_date',
        render: ({ current_status_name, current_status_color }) => {
          return (
            <div className='status-label' style={{marginTop: '1px', backgroundColor: current_status_color || '#1d2c47'}}>
              { current_status_name }
            </div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'As Of Today',
        width: 6,
        key: 'due_remaining_mins',
        render: ({ due_remaining_mins }) => {
          const weekText = formatter.toWeekDayText(due_remaining_mins)
          const dayText = formatter.toDaysText(due_remaining_mins)
          const isToday = dayText === 'TODAY'
          const isOverdue = due_remaining_mins < 0

          return (
            <div className={isOverdue ? 'overmax-text' : ''}>{ `${weekText} ${isToday ? '' : `(${dayText})`} ${isToday ? 'OVERDUE' : isOverdue ? 'OVERDUE' : 'to go'}` }</div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>


        <Page.Content full>
          <Page.Header title={title}>
            { this.hasAccess(Permissions.REPORT.TASK_DUE.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.export()}>
                EXPORT
              </Button>
              : null }
          </Page.Header>

          <div style={{marginTop: '24px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div className='report'>
                  <div className='report-title'>{`Tasks to due `} <span className='report-tag'>{list.total}</span></div>
                  <List cols={columns} rows={list.list} />

                  <Pager
                    size={list.total}
                    total={list.total}
                    totalText={`Total ${list.total} Record${list.total === 1 ? '' : 's'}`}
                    current={currentPage}
                    onChange={(e) => this.onChangePage(e)}
                    style={{ marginTop: '15px' }}
                  />
                </div>
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  onChangePage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchReport()
    })
  }

  fetchReport = () => {
    const { currentPage, filter, isLoaded, loading, sort } = this.state
    this.fetchClientReport({ currentPage, filter, isLoaded, loading, sort })
  }

  fetchClientReport = async ({ currentPage, filter, isLoaded = true, loading, sort }) => {
    if (this.hasAccess(Permissions.REPORT.TASK_DUE.LIST)) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const list = await reportTypeService.listReport(ReportType.SC_CLIENT_TASK_DUE, filter, sort)

        if (list) {
          this.setState({
            list,
            isLoaded: true,
            loadingList: false,
            loading: false
          })
        } else {
          notify.error(`Unable to get ${title} Report`, 'Please try again later.')
        }
      } catch (e) {
        notify.error(`Unable to get ${title} Report`, 'Please try again later.')
      }

      if (window) window.scrollTo({ top: 0 })
    }
  }

  handleSort = (sort, statusType) => {
    for (var s in sort) {
      if (sort[s] === 0) {
        delete sort[s]
      }
    }

    if (!validator.isNotEmptyObject(sort)) {
      sort = Object.assign({}, DefaultSort)
    }

    this.setState({ sort, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  export = async () => {
    try {
      this.setState({ isGenerating: true })

      const r = await exportFile.fetchReportExport(ReportType.SC_CLIENT_TASK_DUE)
      setTimeout(() => {
        this.setState({ isGenerating: false })
      }, 2000)
    } catch (e) {
      notify.error(`Unable to download ${title} Report`, 'Please try again later.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

export default ReportTaskDue
