/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep, isEqual } from 'lodash'
import Moment from 'moment-timezone'
import { AustralianStates, Permissions } from '../../../constants'
import { authService, clientService, employeeService, settingGeneralService, settingReasonService } from '../../../services'
import { fetchingClients, fetchClientLimit, setRefreshActivityLog } from '../../../states/actions'
import { formatter, log, validator } from '../../../util'

// UI
import { CustomIdentifierList, Loading, Page, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import ClientActivity from '../ActivityLog'
import ClientBudget from '../Budget'
import ClientCareplan from '../Careplan'
import ClientFiles from '../File'
import ClientProvider from '../Provider'
import ClientTask from '../Task'

const { Item: FormItem } = Form
const { confirm } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const clientModule = 'client'
const defaultFunderId = 1 // for SC, funder id is fixed to 1 (NDIS)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySidePrivateLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  // { tabId: 2, path: '/custom-identifier' },
  { tabId: 3, path: '/tasks' },
  { tabId: 4, path: '/careplan' },
  { tabId: 5, path: '/funding-plan' },
  { tabId: 6, path: '/providers' },
  { tabId: 7, path: '/files' },
  { tabId: 8, path: '/logs' }
]

export class Client extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      employeeList: [],
      item: {},
      itemOri: {},
      isInactiveUpdate: false,
      loading: false,
      loadingEmployee: false,
      showAuthorizeRep: false,
      showSave: false,
      showEdit: true,
      showInactiveModal: false,
      currentAge: '',
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      currentSubTab: '1',
      latitude: null,
      longitude: null,

      // settings
      genders: [],
      heights: [],
      languages: [],
      reasons: [],
      relationships: [],
      serviceProviders: [],
      settings: []
    }

    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    const { fetchClientLimit } = this.props
    fetchClientLimit({ loading: true })

    if (this.isEdit()) {
      this.fetchClient()
    }

    this.fetchEmployees()
    this.fetchSettings()
    this.fetchReasons()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    if (place && validator.isNotEmptyArray(place.address_components)) {
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0]
        if (addressType === 'locality') {
          suburb = place.address_components[i]['long_name']
        } else if (addressType === 'postal_code') {
          postcode = place.address_components[i]['long_name']
        } else if (addressType === 'administrative_area_level_1') {
          state = place.address_components[i]['long_name']
        }
      }
    }

    // console.log(place.geometry.location.lat(), place.geometry.location.lng())

    this.setState({ latitude: place.geometry ? place.geometry.location.lat() : null, longitude: place.geometry ? place.geometry.location.lng() : null })
    this.props.form.setFieldsValue({ address: place.formatted_address, postcode, state, suburb })
  }


  render () {
    const { clientLimit, history } = this.props
    const { currentTab, currentSubTab, item, loading, showSave, showEdit } = this.state
    const clientId = this.getId()
    const clientRefId = this.getRefId()
    const isInfoTab = currentTab === '1' && currentSubTab === '1'
    const isItem = item && item.id

    const clashedAddr = item.clashed_address_list || []

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit()
            ? 'Participant (Add)'
            : loading
              ? <div className='client-panel-header-skeleton' style={{ width: 200 }} />
              : !isInfoTab
                ? `${item.first_name} ${item.last_name}`
                : showEdit
                  ? `${item.first_name} ${item.last_name} (View Only)`
                  : showSave
                    ? `${item.first_name} ${item.last_name} (Edit Mode)`
                    : 'Participant'}>

            {this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.DELETE)
              ? showSave && isInfoTab
                ? (
                  <div className='btn btn-ghost' onClick={this.handlePreDelete} style={{ marginRight: 20 }}>
                    Delete
                  </div>
                )
                : null
              : null}

            { showEdit && this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.UPDATE) && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                Edit
                </div>)
              : null }

            {((!this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.CREATE) && clientLimit && clientLimit.can_add) ||
            (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.UPDATE))) && isInfoTab
              ? (
                <div className='btn' onClick={loading ? () => {} : this.handleSave}>
                Save
                </div>)
              : null }
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>
          <div className='client-panel'>
            { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
              ? <div className={`client-panel-header ${item && item.active === false ? 'disabled' : ''}`}>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='client-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={5}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <div>
                    <Row>
                      <Col lg={3} style={{ textAlign: 'center' }}>
                        <img alt='emp-gender' src={process.env.PUBLIC_URL + '/img/' + (item.gender ? (item.gender.toLowerCase() === 'male' ? 'male.png' : item.gender.toLowerCase() === 'female' ? 'female.png' : 'user.png') : 'user.png')} className='avatar' />
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-label'>Name</div>
                        <div className='client-panel-header-value'>{item.first_name} {item.last_name}<div className='client-panel-header-subvalue'>{ item.acc_ref }</div></div>
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-label'>NDIS Number</div>
                        <div className='client-panel-header-value'>{item.ndis_number || '-'}&nbsp;&nbsp;{validator.isNotEmptyArray(clashedAddr)
                        ? <Tooltip style={{cursor: 'pointer'}} mouseLeaveDelay={0} title={<div>Same address with participant: {clashedAddr.map(e => e.name).join('; ')}</div>}>
                          <Icon type='home' theme='twoTone' twoToneColor='#d60b29' />
                        </Tooltip>
                        : null }</div>
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-label'>DOB</div>
                        <div className='client-panel-header-value'>{formatter.toShortDate(item.dob) || '-'}</div>
                      </Col>
                      <Col lg={8}>
                        <div className='client-panel-header-label'>Current Plan</div>
                        <div className='client-panel-header-value'>
                          {item.current_plan && item.current_plan.id ? `${formatter.toShortDate(item.current_plan.period_start_date)} - ${formatter.toShortDate(item.current_plan.period_end_date)}` : 'N/A'}
                          {item.current_plan && item.current_plan.id && item.current_plan.cat_name ? <div className='client-panel-header-subvalue'>{ item.current_plan.cat_name }{item.current_plan.cat_identifier ? <span className='client-panel-header-subvalue'>{`  [${item.current_plan.cat_identifier}]`}</span>: null}</div> : null }
                        </div>
                      </Col>
                    </Row>
                    </div> }
              </div>
              : null }
              <div className='client-panel-body'>
                <Tabs
                  defaultActiveKey={currentTab}
                  onChange={this.handleTabChange}
                >
                  <TabPane tab='Participant Info' key='1'>
                    <Tabs
                      type='card'
                      defaultActiveKey={currentSubTab}
                      onChange={this.handleSubTabChange}
                    >
                      <TabPane tab='Info' key='1'>
                        { this.infoTab() }
                      </TabPane>
                      { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                        ? <TabPane tab='Custom Identifier' key='2'>
                          <CustomIdentifierList key={`cidftab${currentTab}`} genreId={clientId} genre={'participant'} history={this.props.history} permission={Permissions.PARTICIPANT.INFO.UPDATE} />
                        </TabPane>
                        : null }
                    </Tabs>
                  </TabPane>
                  { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.LIST)
                    ? <TabPane tab='NDIS Plan' key='5'>
                      <ClientBudget key={`fdsctab${currentTab}`} clientId={clientId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                  { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.CAREPLAN.LIST)
                    ? <TabPane tab='Care Plan' key='4'>
                      <ClientCareplan key={`crpltab${currentTab}`} clientId={clientId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                  { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.TASKS.LIST)
                    ? <TabPane tab='Tasks' key='3'>
                      <ClientTask key={`ctsktab${currentTab}`} clientId={clientId} clientRefId={clientRefId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                  { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.PROVIDER.LIST)
                    ? <TabPane tab='Provider' key='6'>
                      <ClientProvider key={`cprtab${currentTab}`} clientId={clientId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                  { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.FILES.LIST)
                    ? <TabPane tab='Files' key='7'>
                      <ClientFiles key={`frctab${currentTab}`} clientId={clientId} clientInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                   { this.isEdit() && this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                    ? <TabPane tab='Activity Log' key='8'>
                      <ClientActivity key={`actab${currentTab}`} clientId={clientId} history={this.props.history} />
                    </TabPane>
                    : null }
                </Tabs>
              </div>
            </div>
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      currentAge,
      employeeList,
      isInactiveUpdate,
      item,
      genders,
      heights,
      loading,
      loadingEmployee,
      languages,
      relationships,
      reasons,
      serviceProviders,
      showAuthorizeRep,
      showInactiveModal
    } = this.state

    const isEdit = this.isEdit()
    const exitedReason = form.getFieldValue('exited_reason')

    return (
      <Form>
        <Loading loading={loading} blur>
          <Panel title='Account'>
            <Row>
              { isEdit
                ? <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                    {getFieldDecorator('acc_ref', {
                      initialValue: item.acc_ref
                    })(
                      <Input readOnly />
                    )}
                  </FormItem>
                </Col>
                : null }
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Active'>
                  {getFieldDecorator('active', {
                    initialValue: item.active !== undefined ? item.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      onChange={this.handleActiveChange}
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
                <Row>
                  <Col lg={8} />
                  <Col lg={12}>
                    { exitedReason
                      ? <div className='client-exit-reason '>
                        Inactive Reason: { exitedReason }
                      </div>
                      : null }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Assigned SC'>
                  {getFieldDecorator('sc_managed_employee_id', {
                    initialValue: item.sc_managed_employee_id
                  })(
                    <Select
                      allowClear
                      showSearch
                      notFoundContent={<div>No Employees available</div>}
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)
                      }>
                      { employeeList.map((emp) => {
                          return <Option key={`empls-${emp.id}`} value={emp.id}>{emp.fullname}</Option>
                        }) }
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel title='Information'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='First Name' hasFeedback>
                  {getFieldDecorator('first_name', {
                    initialValue: item.first_name,
                    rules: [
                      { min: 2, message: 'First Name must be between 2 and 128 characters' },
                      { max: 128, message: 'First Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter first name' },
                      { whitespace: true, message: 'Please enter first name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Last Name' hasFeedback>
                  {getFieldDecorator('last_name', {
                    initialValue: item.last_name,
                    rules: [
                      { min: 2, message: 'Last Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Last Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter last name' },
                      { whitespace: true, message: 'Please enter last name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Preferred Name' hasFeedback>
                  {getFieldDecorator('preferred_name', {
                    initialValue: item.preferred_name,
                    rules: [
                      { min: 2, message: 'Preferred Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Preferred Name must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter last name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={19}>
                    <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth' hasFeedback>
                      {getFieldDecorator('dob', {
                        initialValue: item.dob ? Moment(item.dob) : null,
                        rules: [
                          { required: true, message: 'Please select dob.' }
                        ]
                      })(
                        <DatePicker format={dateFormat} onChange={this.handleDOBChange} />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={5}>
                    <div className='client_age'>
                      {currentAge} years old
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Gender' hasFeedback>
                  {getFieldDecorator('gender', {
                    initialValue: item.gender,
                    rules: [
                      { required: true, message: 'Please select gender.' }
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)
                      }>
                      {
                        genders.map((gender) => {
                          return <Option key={`gendes-${gender.id}`} value={gender.value}>{gender.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='NDIS Number' hasFeedback>
                  {getFieldDecorator('ndis_number', {
                    initialValue: item.ndis_number,
                    rules: [
                      { required: true, message: 'Please enter NDIS Number' },
                      { min: 2, message: 'NDIS Number must be between 2 and 128 characters' },
                      { max: 128, message: 'NDIS Number must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter NDIS Number' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Sexual Identity' hasFeedback>
                  {getFieldDecorator('gender_second', {
                    initialValue: item.gender_second
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)
                      }>
                      {
                        genders.map((gender) => {
                          return <Option key={`gensecdes-${gender.id}`} value={gender.value}>{gender.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                  {getFieldDecorator('email', {
                    initialValue: item.email,
                    rules: [
                      {
                        type: 'email',
                        message: 'Please provide a valid Email'
                      }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Mobile Number' hasFeedback>
                  {getFieldDecorator('mobile_phone', {
                    initialValue: item.mobile_phone,
                    rules: [
                      { min: 2, message: 'Mobile Number must be between 2 and 128 characters' },
                      { max: 128, message: 'Mobile Number must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter phone number' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                  {getFieldDecorator('phone', {
                    initialValue: item.phone,
                    rules: [
                      { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                      { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter phone number' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>

            <div>
              <FormItem {...formItemLayout} label='Address' hasFeedback>
                {getFieldDecorator('address', {
                  initialValue: item.address,
                  rules: [
                    { min: 2, message: 'Address must be between 2 and 128 characters' },
                    { max: 128, message: 'Address must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter address' },
                    { whitespace: true, message: 'Please enter address' }
                  ]
                })(
                  <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
                )}
              </FormItem>

              <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
                {getFieldDecorator('unit_building', {
                  initialValue: item.unit_building,
                  rules: [
                    { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                    { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter unit/building info' }
                  ]
                })(
                  <Input placeholder='Please Enter Unit No/Building Name' />
                )}
              </FormItem>

              <Row style={{ display: 'none' }}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                    {getFieldDecorator('suburb', {
                      initialValue: item.suburb
                    })(
                      <Input disabled />
                    )}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Country'>
                    {getFieldDecorator('country', {
                      initialValue: 'Australia'
                    })(
                      <Input disabled />
                    )}
                  </FormItem>

                </Col>
              </Row>

              <Row style={{ display: 'none' }}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='State'>
                    {getFieldDecorator('state', {
                      initialValue: item.state || null
                    })(
                      <Select placeholder='Please select a state' disabled>
                        {
                          AustralianStates.map((states, idx) => (
                            <Option key={`stx-${states.value}`} value={states.value}>{states.name}</Option>
                          ))
                        }
                      </Select>
                    )}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Postcode' hasFeedback>
                    {getFieldDecorator('postcode', {
                      initialValue: item.postcode
                    })(
                      <Input disabled />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Has Authorized Rep. ?'>
                  {getFieldDecorator('authorize_rep', {
                    initialValue: item.authorize_rep || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handleAuthorizeRepChange}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          { showAuthorizeRep
            ? <div className='show-authorize-field'>
              <Panel title='Authorized Representative'>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Name'>
                      {getFieldDecorator('authorize_rep_name', {
                        initialValue: item.authorize_rep_name || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Contact'>
                      {getFieldDecorator('authorize_rep_phone', {
                        initialValue: item.authorize_rep_phone,
                        rules: [
                          { min: 2, message: 'Contact must be between 2 and 128 characters' },
                          { max: 128, message: 'Contact must be between 2 and 128 characters' },
                          { whitespace: true, message: 'Please enter contact' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Email'>
                      {getFieldDecorator('authorize_rep_email', {
                        initialValue: item.authorize_rep_email || null
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                      {getFieldDecorator('authorize_rep_relationship', {
                        initialValue: item.authorize_rep_relationship
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            this.filterOptions(input, option)}
                        >
                          { relationships.map((relation, idx) => {
                              return <Option key={`relas-${relation.id}`} value={relation.value}>{relation.name}</Option>
                            }) }
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
            </div>
            : null
          }

          <Panel title='Alerts'>
            <Row>
              {/* <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Public Alert'>
                  {getFieldDecorator('public_alert', {
                    initialValue: item.public_alert || null
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col> */}
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Private Alert'>
                  {getFieldDecorator('private_alert', {
                    initialValue: item.private_alert || null
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel title='Preferences and Needs'>
            <Row>
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Cultural and Religion Needs' hasFeedback>
                  {getFieldDecorator('pref_cultural_religion_needs', {
                    initialValue: item.pref_cultural_religion_needs
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Interpreter Required?'>
                  {getFieldDecorator('pref_is_interpreter_required', {
                    initialValue: this.isEdit() ? item.pref_is_interpreter_required : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Aboriginal or Torres Strait Islander'>
                  {getFieldDecorator('pref_is_aboriginal', {
                    initialValue: this.isEdit() ? item.pref_is_aboriginal : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Preferred Languages' hasFeedback>
                  {getFieldDecorator('preference.languages', {
                    initialValue: item.preference && validator.isNotEmptyArray(item.preference.languages) ? item.preference.languages : []
                  })(
                    <Select
                      mode='multiple'
                      showSearch
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)}
                    >
                      {
                        languages.map((language) => {
                          return <Option key={`lang${language.id}`} value={language.id}>{language.name}</Option>
                        })
                      }
                    </Select>
                )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Preferred Services' hasFeedback>
                  {getFieldDecorator('preference.services_provider', {
                    initialValue: item.preference && validator.isNotEmptyArray(item.preference.services_provider) ? item.preference.services_provider : []
                  })(
                    <Select
                      mode='multiple'
                      showSearch
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)}
                    >
                      { serviceProviders.map((sc) => {
                          return <Option key={`lang${sc.id}`} value={sc.id}>{sc.name}</Option>
                        }) }
                    </Select>
                )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Health Care Professionals' hasFeedback>
                  {getFieldDecorator('pref_health_info', {
                    initialValue: item.pref_health_info
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Companion Card'>
                  {getFieldDecorator('pref_is_companion_card', {
                    initialValue: this.isEdit() ? item.pref_is_companion_card : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Disabled Parking'>
                  {getFieldDecorator('pref_is_disabled_parking', {
                    initialValue: this.isEdit() ? item.pref_is_disabled_parking : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row>
              <Col lg={24}>
                <FormItem {...formItemLayout} label='Private Notes' hasFeedback>
                  {getFieldDecorator('private_notes', {
                    initialValue: item.private_notes
                  })(
                    <TextArea rows={4} />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>

          <Panel title='Second Contact'>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Name'>
                  {getFieldDecorator('second_contact_name_1', {
                    initialValue: item.second_contact_name_1,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                  {getFieldDecorator('second_contact_phone_1', {
                    initialValue: item.second_contact_phone_1,
                    rules: [
                      { min: 2, message: 'Contact must be between 2 and 128 characters' },
                      { max: 128, message: 'Contact must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter contact' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>

            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                  {getFieldDecorator('second_contact_relationship_1', {
                    initialValue: item.second_contact_relationship_1,
                    rules: [
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)}
                    >
                      { relationships.map((relation, idx) => {
                          return <Option key={`rsdx-${relation.value}`} value={relation.value}>{relation.name}</Option>
                        }) }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Email'>
                  {getFieldDecorator('second_contact_email_1', {
                    initialValue: item.second_contact_email_1
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <hr className='contact-separator' />
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Name'>
                  {getFieldDecorator('second_contact_name_2', {
                    initialValue: item.second_contact_name_2,
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                  {getFieldDecorator('second_contact_phone_2', {
                    initialValue: item.second_contact_phone_2,
                    rules: [
                      { min: 2, message: 'Contact must be between 2 and 128 characters' },
                      { max: 128, message: 'Contact must be between 2 and 128 characters' },
                      { whitespace: true, message: 'Please enter contact' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                  {getFieldDecorator('second_contact_relationship_2', {
                    initialValue: item.second_contact_relationship_2,
                    rules: [
                    ]
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        this.filterOptions(input, option)}
                    >
                      {
                        relationships.map((relation, idx) => {
                          return <Option key={`rs2dx-${relation.value}`} value={relation.value}>{relation.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Email'>
                  {getFieldDecorator('second_contact_email_2', {
                    initialValue: item.second_contact_email_2
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
            </Row>
          </Panel>
          <SideModal
            title='You Have Changed Active Status'
            showModal={showInactiveModal}
            onClose={() => this.handleInactiveReason(false, true)}
            buttons={[
              <Button key='exitrsok' type='primary' onClick={() => this.onSubmitInactiveReason()}>Submit</Button>
            ]}
          >
            <FormItem label='Reason'>
              {getFieldDecorator('exited_reason', {
                rules: [
                  isInactiveUpdate ? { required: true, message: 'Please select reason' } : {}
                ]
              })(
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    this.filterOptions(input, option)}
                >
                  {
                    reasons.map((reason) => {
                      return <Option key={`rsbv-${reason.name}`} value={reason.name}>{reason.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Note'>
              {getFieldDecorator('exited_reason_note', {
                rules: [
                  // isInactiveUpdate ? { required: true, message: 'Please enter note' } : {}
                ]
              })(
                <TextArea rows={4} />
              )}
            </FormItem>
          </SideModal>
        </Loading>
      </Form>
    )
  }

  fetchClient = async () => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.READ)) {
      return
    }

    try {
      const { form } = this.props
      const refId = this.getRefId()
      let id = null

      this.setState({ loading: true })
      const item = await clientService.getRef(refId)

      if (item && item.id) {
        id = item.id
        // get current age
        this.handleAgeUpdate(item.dob)
        const defaultAuthorize = item.authorize_rep

        this.setState({
          item,
          itemOri: cloneDeep(item),
          loading: false,
          showAuthorizeRep: defaultAuthorize
        })
      } else {
        notify.error('Unable to get successfully', 'Unable to get participant successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to get successfully', 'Unable to get participant successfully. Please try again later.')
    }

    this.scrollToTop()
  }

  fetchEmployees = async () => {
    if (!this.hasAccess(Permissions.EMPLOYEE.INFO.LIST)) {
      return
    }
    this.setState({ loadingEmployee: true })

    const list = await employeeService.listAllEmployees()

    this.setState({
      employeeList: list && validator.isNotEmptyArray(list) ? list : [],
      loadingEmployee: true
    })
  }

  fetchReasons = async () => {
    const filter = {}
    filter.type = { condition: '=', value: 'inactive-client' }

    const reasons = await settingReasonService.listReasonItemByPage(1, 0, filter)

    if (reasons && validator.isNotEmptyArray(reasons.list)) {
      this.setState({ reasons: reasons.list })
    } else {
      this.setState({ reasons: [] })
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'language' },
        { condition: '=', value: 'sc-provider-services' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'relationship' },
        { condition: '=', value: 'suburb-select' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      languages: settings.list.filter(item => item.identifier === 'language'),
      genders: settings.list.filter(item => item.identifier === 'gender'),
      heights: settings.list.filter(item => item.identifier === 'height'),
      relationships: settings.list.filter(item => item.identifier === 'relationship'),
      serviceProviders: settings.list.filter(item => item.identifier === 'sc-provider-services')
    })
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleActiveChange = (value) => {
    const { form } = this.props
    const { setFieldsValue } = form

    if (this.isEdit()) {
      if (!value) {
        this.handleInactiveReason(true)
      } else {
        this.resetInactiveReasons()
      }

      this.setState({ isInactiveUpdate: !value })
    }
  }

  handlePreDelete = () => {
    const { loading } = this.state
    if (loading) return

    const { handleDelete } = this
    const id = this.getId()

    confirm({
      title: 'Delete this participant?',
      content: 'THIS ACTION CANNOT UNDO! Press Ok to continue, Cancel to return',
      async onOk () {
        handleDelete(id)
      },
      onCancel () {
      }
    })
  }

  handleDelete = async (id) => {
    const { fetchingClients, history } = this.props

    this.setState({ loading: true })

    const r = await clientService.remove(id)

    if (r && r.id) {
      this.handleDeleteSuccessful()
      log.deleteClient(id, 'Participant is deleted.')
      history.replace('/participants')
      fetchingClients(true)
    } else {
      this.handleDeleteError()
    }

    this.setState({ loading: false })
  }

  handleDeleteSuccessful = () => {
    notify.success('Deleted successfully', 'Participant deleted successfully.')
  }

  handleDeleteError = () => {
    notify.error('Unable to delete successfully', 'Unable to delete participant successfully. Please try again later.')
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSubTabChange = (index) => {
    this.setState({currentSubTab: index})
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/participants/${refId}${tab.path}`)
    }
  }

  handleSADateChange = (e) => {
    this.checkActiveDisable(e)
  }

  handleAuthorizeRepChange = (e) => {
    if (e === false) {
      this.setState({ showAuthorizeRep: false })
    } else {
      this.setState({ showAuthorizeRep: true })
    }
  }

  handleDOBChange = (e) => {
    this.handleAgeUpdate(e)
  }

  handleAgeUpdate = (value) => {
    const empDate = Moment(value)
    const currYear = Moment()
    const ageNum = currYear.diff(empDate, 'years')

    this.setState({ currentAge: ageNum })
  }

  handleInactiveReason = (showInactiveModal, isForceReset = false) => {
    const { form } = this.props
    const { item } = this.state
    this.setState({ showInactiveModal }, () => {
      if (isForceReset) {
        form.setFieldsValue({ active: item.active })
      }
    })
  }

  onSubmitInactiveReason = () => {
    const { form } = this.props
    form.validateFieldsAndScroll(['exited_reason', 'exited_reason_note'], async (errors, values) => {
      if (!errors) {
        this.handleInactiveReason(false)
      }
    })
  }

  handleSave = (e) => {
    e.preventDefault()
    const { loading } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = ''

    if (loading) {
      return
    }

    this.setState({ loading: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          const { fetchingClients, history } = this.props
          const { employeeList, item, longitude, latitude, loading } = this.state

          if (longitude && latitude) {
            values.longitude = longitude
            values.latitude = latitude
          }

          if (this.isEdit()) {
            if (values.active === false) {
              if (values.exited_reason && values.exited_reason !== '') {
                extraLog += `Inactive Reason "${values.exited_reason}" ${values.exited_reason_note ? `, Inactive Note "${values.exited_reason_note}"` : ''}`
              }
            } else {
              values.exited_reason = null
              values.exited_reason_note = null
            }
          }

          try {
            const currEmployee = employeeList.find(e => e.id === values.sc_managed_employee_id)

            if (this.isEdit()) {
              const response = await clientService.save(item.id, values)

              if (response.id) {
                //--- update client log construction starts
                const prevItem = cloneDeep(this.state.itemOri)
                const currItem = cloneDeep(values)

                const prevPref = prevItem.preference
                const currPref = currItem.preference

                delete prevItem.preference
                delete currItem.preference
                delete prevItem.clashed_address_list
                delete currItem.clashed_address_list

                const prefChanges = this.getPreferenceChangeWithName(
                  prevPref,
                  currPref,
                  [
                    'languages',
                    'services_provider'
                  ],
                  [
                    'Preferred Languages',
                    'Services Provided'
                  ])
                extraLog += prefChanges ? `${extraLog ? ', ' : ''} ${prefChanges}` : ''

                const prevEmployee = item.sc_managed_employee_name || ''
                if (prevItem.sc_managed_employee_id !== currItem.sc_managed_employee_id) {
                  extraLog += `${extraLog ? ', ' : ''} Assigned SC changed from "${prevEmployee}" to "${currEmployee ? currEmployee.fullname : ''}"`
                }

                log.updateClient(
                  response.id,
                  prevItem,
                  currItem,
                  ['preferences', 'sc_managed_employee_id', 'exited_reason', 'exited_reason_note'],
                  [
                    { key: 'gender_second', label: 'Sexual Identity' },
                    { key: 'pref_is_interpreter_required', label: 'Interpreter Required' },
                    { key: 'pref_cultural_religion_needs', label: 'Cultural and Religion Needs' },
                    { key: 'pref_health_info', label: 'Health Care Professionals' },
                    { key: 'pref_is_companion_card', label: 'Companion Card' },
                    { key: 'pref_is_disabled_parking', label: 'Disabled Parking' },
                    { key: 'pref_is_aboriginal', label: 'Aboriginal or Torres Strait Islander' },
                  ],
                  extraLog)

                this.handleSaveSuccessful()
                fetchingClients(true)
                this.fetchClient()
                this.resetInactiveReasons()
              } else {
                this.handleSaveError()
              }
            } else {
              const response = await clientService.add(values)

              if (response.id) {
                const { id, ref_id, acc_ref } = response
                this.setState({ item: { ...item, ...values, id, ref_id, acc_ref } })
                log.addClient(id, `New participant ${values.first_name} ${values.last_name}`)
                this.handleSaveSuccessful()
                window.location.replace(`/participants/${ref_id}/info`)
                fetchingClients(true)
                this.resetInactiveReasons()
              } else {
                this.handleSaveError()
              }
            }

            this.handleAgeUpdate(values.dob)

            this.props.setRefreshActivityLog(true)
          } catch (e) {
            console.log('error', e)
            this.handleSaveError()
          }
        }

        this.setState({ loading: false })
      })
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Participant saved successfully.')
  }

  handleSaveError = () => {
    notify.error('Unable to save successfully', 'Unable to save participant successfully. Please try again later.')
  }

  validatePMAuthAmountChange = (rule, value, callback) => {
    const pValue = parseFloat(value)
    if (value === null || value === undefined || value === '') {
      callback()
    } else if (pValue === 0) {
      callback(new Error(`Authorisation Amount is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Authorisation is not number or decimal format`))
      } else {
        callback()
      }
    }
  }

  getPreferenceChange = (prevPref, currPref, fields = [], fieldsName = []) => {
    let changeText = ''
    const { settings } = this.state

    if (fields.length === fieldsName.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const prev = prevPref[field]
        const curr = currPref[field]

        if (!isEqual(prev, curr)) {
          let prevText = []
          let currText = []
          if (validator.isArray(prev)) {
            for (let j = 0; j < prev.length; j++) {
              const s = settings.find(e => e.id === prev[j])
              if (s && s.id) {
                prevText.push(s.name)
              }
            }
          }

          if (validator.isArray(curr)) {
            for (let j = 0; j < curr.length; j++) {
              const s = settings.find(e => e.id === curr[j])
              if (s && s.id) {
                currText.push(s.name)
              }
            }
          }

          changeText += `${changeText ? ', ' : ''}${fieldsName[i]} from "${prevText.join(', ')}" to "${currText.join(', ')}"`
        }
      }
    }

    return changeText
  }

  getPreferenceChangeWithName (prevPref, currPref, fields = [], fieldsName = []) {
    let changeText = ''
    const { settings } = this.state

    if (fields.length === fieldsName.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const prevList = prevPref[`${field}_list`]
        const curr = currPref[field]
        const currArray = []

        if (validator.isArray(curr)) {
          for (let j = 0; j < curr.length; j++) {
            const s = settings.find(e => e.id === curr[j])
            if (s && s.id) {
              currArray.push(s)
            }
          }

          currArray.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            else return 0
          })
        }

        const prevText = validator.isArray(prevList) ? prevList.join(', ') : ''
        const currText = currArray.map(e => e.name).join(', ')

        if (prevText !== currText) {
          changeText += `${changeText ? ', ' : ''}${fieldsName[i]} from "${prevText}" to "${currText}"`
        }
      }
    }

    return changeText
  }

  resetInactiveReasons = () => {
    const { form } = this.props
    form.setFieldsValue({ exited_reason: undefined, exited_reason_note: undefined })

    this.setState({ isInactiveUpdate: false })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  getPath = () => {
    const { match } = this.props
    const { params } = match
    const { path = '' } = params
    return path
  }

  scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
  fetchingClients,
  fetchClientLimit,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Client))
