import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clientService, employeeService, funderService, taskService, taskJobService } from '../../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../../states/actions'
import { Permissions, FundingManagedTypes, JobSaveType, TaskPriority } from '../../../../constants'
import moment from 'moment-timezone'
import { auth, common, formatter, log, trigger, validator } from '../../../../util'
import { cloneDeep, isEqual } from 'lodash'

// UI
import { DurationPicker, Loading, Page, Panel, SideModal } from '../../../../components'
import notify from '../../../../components/Notification'
import Alert from 'antd/lib/alert'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import './styles.css'

import TaskJobActivity from '../ActivityLog'
import TaskJobFile from '../File'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySidePrivateLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const dateFormat = 'DD/MM/YYYY'
const DefaultDuration = 12

const checkDateInPlanPeriod = (value, budgetList, funderInfo) => {
  const date = moment.isMoment(value) ? value : moment(value)
  let budget = null

  const bgList = budgetList

  if (funderInfo && funderInfo.id) {
    const bgts = bgList.filter(e => e.funder_id === funderInfo.id)

    for (let i = 0; i < bgts.length; i++) {
      const cb = bgts[i]
      const std = moment.isMoment(cb.period_start_date) ? cb.period_start_date : moment(cb.period_start_date)
      const etd = moment.isMoment(cb.period_end_date) ? cb.period_end_date : moment(cb.period_end_date)


      if (std.isSameOrBefore(date) && etd.isSameOrAfter(date)) {
        budget = cb
        break
      }
    }
  }

  return budget
}

export class AddJobModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      clientInfo: {},
      clientBudgetList: [],
      currentBudgetInfo: {},
      currentTab: '1',
      employeeList: [],
      funderInfo: {},
      isEdit: false,
      isDataLoaded: false,
      item: {},
      itemOri: {},
      loading: false,
      loadingClient: false,
      loadingEmployee: false,
      loadingFunder: false,
      loadingJobs: false,
      loadingUpdate: false,
      serviceList: [],
      statusList: [],
      taskInfo: {},
      visible: false,
      userId: null,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clientInfo, funderInfo, clientBudgetList, currentBudgetInfo, employeeList, clientId, item, serviceList, statusList, taskInfo, visible } = nextProps

    const isEdit = item && item.id

    let state = {}

    // only update the state from props when isDataLoaded = false and visible = true coming next
    if (prevState.isDataLoaded === false && prevState.visible === false && visible === true) {
      const userId = auth.getCurrentUserId()
      const currentBudget = item && item.id && item.job_date ? checkDateInPlanPeriod(item.job_date, clientBudgetList, funderInfo) : {}
      const scMember = !isNaN(parseInt(userId)) ? employeeList.find(e => parseInt(e.sc_member_id) === parseInt(userId)) : null

      if (!isEdit && scMember && scMember.id) {
        item.employee_id = scMember.id
      }

      // const currentUserEmployee =
      state = {
        ...prevState,
        clientId,
        clientInfo,
        funderInfo,
        clientBudgetList,
        currentBudgetInfo: currentBudgetInfo && currentBudgetInfo.id ? currentBudgetInfo : currentBudget,
        employeeList,
        item,
        itemOri: cloneDeep(item),
        isEdit,
        serviceList,
        statusList,
        taskInfo,
        visible,
        userId,
        isDataLoaded: true
      }
    } else {
      state = { ...prevState, visible }
    }

    return state
  }

  render () {
    const { currentTab, isEdit, item, loadingUpdate, taskInfo, visible } = this.state

    const taskJobId = this.getId()
    const action = isEdit ? 'Update' : 'Add'
    const isEditTab = currentTab === '1'
    const isCancellable = isEdit ? (!!item.is_cancellable) : false
    const isCancelled = isEdit ? (!!item.is_cancelled) : false

    return (
      <Modal
        width='80%'
        title={`${action} Job${isEdit && item.id ? ` - ${formatter.capitalize(item.id_numbering, false)}` : ''}`}
        visible={visible}
        onCancel={() => this.onClose()}
        maskClosable={false}
        footer={
          [
            <Button key='close' style={{backgroundColor: '#fff'}} loading={loadingUpdate} onClick={() => this.onClose(false)}>Close</Button>,
            isEditTab && isCancellable && !isCancelled
              ? <Button style={{color: '#fff', backgroundColor: '#ff4400'}} key='submitcancel' loading={loadingUpdate} onClick={(e) => this.preHandleCancelJob(e)}>{`Cancel Job`}</Button>
              : null,
            isEditTab && !isCancelled
              ? <Button style={{color: '#fff', backgroundColor: '#3d34eb'}} key='submit1' loading={loadingUpdate} onClick={(e) => this.handleSave(e)}>{`${action} Job`}</Button>
              : null
          ]
        }
      >
        <Tabs
          defaultActiveKey={currentTab}
          onChange={this.handleTabChange}
        >
          <TabPane tab='Job Info' key='1'>
            { this.infoTab() }
          </TabPane>
          { isEdit && this.hasAccess(Permissions.TASKJOB.FILES.LIST)
            ? <TabPane tab='Files' key='2'>
              <TaskJobFile key={`ftsjtab${currentTab}`} taskJobId={taskJobId} taskJobInfo={item} history={this.props.history} />
            </TabPane>
            : null }
          { isEdit && this.hasAccess(Permissions.TASKJOB.INFO.READ)
            ? <TabPane tab='Activity Log' key='3'>
              <TaskJobActivity key={`acjstab${currentTab}`} taskJobId={taskJobId} history={this.props.history} />
            </TabPane>
            : null }
        </Tabs>

      </Modal>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { clientInfo, funderInfo, currentBudgetInfo, employeeList, isEdit, item, loadingUpdate, serviceList, statusList, taskInfo, visible, userId } = this.state

    const isCancelled = isEdit ? !!item.is_cancelled : false

    return (
      <Spin spinning={loadingUpdate}>
        <div className='job-form'>
          <Row gutter={12}>
            <Col lg={12}>
              <div className='info-section'>
                <div className='flex-space-between'>
                  <div>
                    <span className='section-name'><a href={`/participants/${clientInfo.ref_id}/info`} target='_blank'>{ clientInfo.fullname }</a></span>
                    <span className='section-accref'>{ clientInfo.ndis_number }</span>
                  </div>
                </div>
                <div className='flex-left detail-row'>
                  { clientInfo.phone || clientInfo.mobile_phone
                    ? <div className='flex-left'>
                      <Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '3px'}} />
                      &nbsp;<div className='detail-item'>{clientInfo.phone || clientInfo.mobile_phone}</div>&nbsp;&nbsp;
                    </div>
                    : null }
                  { clientInfo.address
                    ? <div className='flex-left'>
                      <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '3px'}} />
                      &nbsp;<div className='detail-item'>{`${clientInfo.unit_building ? `${clientInfo.unit_building}, ` : ''}${clientInfo.address}`}</div>&nbsp;
                    </div>
                    : null }
                </div>
                <div style={{height: '5px'}} />
                <div className='flex-left detail-row'>
                  <div className='flex-left'>
                    <Icon type='font-size' style={{marginRight: '3px', color: '#ed6d1e'}} />
                    &nbsp;{ clientInfo.preference && validator.isNotEmptyArray(clientInfo.preference.languages_list)
                      ? <div className='detail-item'>{clientInfo.preference.languages_list.join(', ')}</div>
                      : <div className='detail-item'>NIL</div> }
                  </div>
                </div>
                <div className='flex-left detail-row'>
                  <div className='flex-left'>
                    <Icon type='customer-service' style={{marginRight: '3px', color: '#ed6d1e'}} />
                    &nbsp;{ clientInfo.preference && validator.isNotEmptyArray(clientInfo.preference.services_provider_list)
                      ? <div className='detail-item'>{clientInfo.preference.services_provider_list.join(', ')}</div>
                      : <div className='detail-item'>NIL</div> }
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className='info-section'>
                { funderInfo && funderInfo.id
                  ? <div className='flex-space-between'>
                    <div>
                      <span className='section-name'><a href={`/funders/${funderInfo.ref_id}/info`} target='_blank'>{ funderInfo.fullname }</a></span>
                      <span className='section-accref'>{ funderInfo.acc_ref }</span>
                    </div>
                  </div>
                  : <div className='warning-msg'>{`No active Plan / Funder Available.`}</div> }
                {/* <div className='flex-left detail-row'>
                  { funderInfo.address
                    ? <div className='flex-left'>
                      <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' style={{marginRight: '3px'}} />
                      <div className='detail-item'>{`${funderInfo.unit_building ? `${funderInfo.unit_building}, ` : ''}${funderInfo.address}`}</div>
                    </div>
                    : null }
                </div> */}
                <div style={{height: '5px'}} />
                <div className='flex-left detail-row'>
                  <Icon type='calendar' style={{marginRight: '3px', color: '#ed6d1e'}} />
                  &nbsp;{ currentBudgetInfo && currentBudgetInfo.id
                      ? <div className='detail-item'>{`${formatter.toShortDate(currentBudgetInfo.period_start_date)} - ${formatter.toShortDate(currentBudgetInfo.period_end_date)}`}</div>
                      : <div className='detail-item'>No valid plan available as of now</div> }
                </div>
                <div className='flex-left detail-row'>
                  <Icon type='database' style={{marginRight: '3px', color: '#ed6d1e'}} />
                  &nbsp;{ currentBudgetInfo && currentBudgetInfo.id
                      ? <div className='detail-item'>{`${currentBudgetInfo.fund_managed_type_name}`}</div>
                      : <div className='detail-item'>SC Fund - Not available</div> }
                </div>
                { currentBudgetInfo && currentBudgetInfo.id
                  ? <div className='flex-left detail-row'>
                    <Icon type='project' style={{marginRight: '3px', color: '#ed6d1e'}} />
                    &nbsp;<div className='detail-item'>{`${currentBudgetInfo.cat_name}${currentBudgetInfo.cat_identifier ? `  [${currentBudgetInfo.cat_identifier}]` : ''}`}</div>
                  </div>
                  : null }
              </div>
            </Col>
          </Row>

          {/** show alert if no active budget at the moment */}
          { isEdit && !item.budget_id
            ? <div style={{marginTop: '20px'}}>
              <Alert message={
                <div dangerouslySetInnerHTML={{
                  __html: `<span style="font-weight: bold;">No valid plan available as of now</span><br />This job will not be listed in Billings if this job does not link with any available plan.`
                }} />
              } type='error' showIcon /><br />
            </div>
            : null }

          {/** force form items rerender or else the details and input values are not updated */}
          { visible
            ? <Form>
              <div style={{height: '10px'}} />
              <Panel
                title={'Job Date'}
                subtitle={<div className='flex-status'>
                  { item.claim_status_name
                    ? <div className='job-status' style={{backgroundColor: item.claim_status_color || '#1d2c47'}}>
                      {item.claim_status_name}
                    </div>
                    : null }
                  { item.id
                    ? <div className='job-status' style={{marginLeft: '8px', backgroundColor: item.is_payroll_updated ? 'red' : '#ccc'}}>
                      {item.is_payroll_updated ? 'Payroll Paid' : 'Payroll Not Paid'}
                    </div>
                    : null }
                </div>
                }
              >
                { currentBudgetInfo && currentBudgetInfo.id
                  ? <div className='warning-msg'>{currentBudgetInfo.remaining_hrs <= 0
                      ? `No more remaining hours under this plan.`
                      : `Current Plan has ${formatter.toFloatDecimal(currentBudgetInfo.remaining_hrs)} hours before editing this job.`}</div>
                  : null }
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Date'>
                      {getFieldDecorator('job_date', {
                        initialValue: item.job_date ? moment(item.job_date) : null,
                        rules: [
                          { required: true, message: ' ' },
                          { validator: this.validateDateChange }
                        ]
                      })(
                        <DatePicker
                          disabled={loadingUpdate || isCancelled}
                          format={dateFormat}
                          onChange={this.handleDateChange}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Duration'>
                      {getFieldDecorator('job_duration', {
                        initialValue: !isEdit
                          ? DefaultDuration
                          : item.job_duration
                            ? item.job_duration
                            : 0,
                        rules: [
                          { required: true, message: 'Please select Job Duration' }
                        ]
                      })(
                        <DurationPicker disabled={loadingUpdate || isCancelled} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Panel>

              <Panel title={'Job Details'}>
                <Form layout='vertical'>
                  <Row gutter={16}>
                    <Col lg={8}>
                      <FormItem label='Support Coordinator'>
                        {getFieldDecorator('employee_id', {
                          initialValue: item.employee_id || null, // preset of employee id for current admin user is done at getDerivedStateFromProps
                          rules: [
                            { required: true, message: 'Please select Support Coordinator' }
                          ]
                        })(
                          <Select
                            disabled={item.is_payroll_updated === true || loadingUpdate || isCancelled}
                            showSearch
                            filterOption={(input, option) =>
                              this.filterOptions(input, option)}
                          >
                            { employeeList.map((s, idx) => {
                                return <Option key={`empsts${s.id}`} value={s.id}>{s.fullname}</Option>
                              }) }
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={8}>
                      <FormItem label='Service'>
                        {getFieldDecorator('service_id', {
                          initialValue: item.service_id || null,
                          rules: [
                            { required: true, message: 'Please select Service' }
                          ]
                        })(
                          <Select
                            disabled={loadingUpdate || isCancelled}
                            showSearch
                            filterOption={(input, option) =>
                              this.filterOptions(input, option)}
                          >
                            { serviceList.map((s, idx) => {
                                return <Option key={`svcmst${s.id}`} value={s.id}>{s.name}</Option>
                              }) }
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={8}>
                      <FormItem label='Task Status'>
                        {getFieldDecorator('status_id', {
                          initialValue: item.status_id || null,
                          rules: [
                            { required: true, message: 'Please select Task Status' }
                          ]
                        })(
                          <Select
                            disabled={loadingUpdate || isCancelled}
                            showSearch
                            filterOption={(input, option) =>
                              this.filterOptions(input, option)}
                          >
                            { statusList.map((s, idx) => {
                                return <Option key={`stmcjs${s.id}`} value={s.id}>{s.name}</Option>
                              }) }
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={8}>
                      <FormItem label='Summary'>
                        {getFieldDecorator('summary', {
                          initialValue: item.summary || null,
                          rules: [
                            { whitespace: true, message: 'Please enter Summary' },
                            { required: true, message: 'Please enter Summary' }
                          ]
                        })(
                          <TextArea
                            rows={4}
                            disabled={loadingUpdate || isCancelled}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={8}>
                      <FormItem label='Details'>
                        {getFieldDecorator('notes', {
                          initialValue: item.notes || null
                        })(
                          <TextArea
                            rows={4}
                            disabled={loadingUpdate || isCancelled}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Form>
            : null }
        </div>
      </Spin>
    )
  }

  onComplete = () => {
    const { onComplete } = this.props

    onComplete()
    this.onClose()
  }

  onClose = () => {
    const { form, onClose } = this.props
    const { loadingUpdate } = this.state
    const { resetFields } = form

    if (loadingUpdate) return

    this.setState({ isDataLoaded: false })
    resetFields()
    onClose()
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleDateChange = (value) => {
    const { clientBudgetList, funderInfo } = this.state
    const newBudget = checkDateInPlanPeriod(value, clientBudgetList, funderInfo)

    this.setState({ currentBudgetInfo: newBudget && newBudget.id ? newBudget : {} })
  }

  handleTabChange = (index) => {
    this.setState({currentTab: index})
  }

  handleSave = async (e) => {
    e.preventDefault()
    const { loading, loadingUpdate } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = ''
    let isSaved = false

    if (loading || loadingUpdate) {
      return
    }

    this.setState({ loadingUpdate: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          try {
            const { isEdit, clientInfo, employeeList, funderInfo, item, statusList, serviceList, taskInfo } = this.state
            values.save_type = JobSaveType.SAVE

            if (isEdit) {
              const r1 = await taskJobService.save(item.id, values)

              if (r1 && r1.id) {
                const { id, ref_id } = r1

                const prevItem = cloneDeep(this.state.itemOri)
                const currItem = cloneDeep(values)

                const prefix = `Task ${taskInfo.title ? `"${taskInfo.title.toUpperCase()}"` : taskInfo.id_numbering ? `"${taskInfo.id_numbering.toUpperCase()}"` : ''} & Job "${formatter.capitalize(item.id_numbering)}"`

                const prevEmployee = prevItem.employee_fullname
                const currEmployee = employeeList.find(e => e.id === values.employee_id)
                if (prevItem.employee_id !== values.employee_id) {
                  extraLog += `${extraLog ? ', ' : ''}Support Coordinator changes from "${prevEmployee}" to "${currEmployee && currEmployee.id ? currEmployee.fullname : ''}"`
                }

                const prevStatus = prevItem.status_name
                const currStatus = statusList.find(e => e.id === values.status_id)
                if (prevItem.status_id !== values.status_id) {
                  extraLog += `${extraLog ? ', ' : ''}Task Status changes from "${prevStatus}" to "${currStatus && currStatus.id ? currStatus.name : ''}"`
                }

                const prevService = prevItem.service_name
                const currService = serviceList.find(e => e.id === values.service_id)
                if (prevItem.service_id !== values.service_id) {
                  extraLog += `${extraLog ? ', ' : ''}Service changes from "${prevService}" to "${currService && currService.id ? currService.name : ''}"`
                }

                const prevDuration= formatter.toDayHourText(prevItem.job_duration)
                const currDuration = formatter.toDayHourText(currItem.job_duration)

                if (prevItem.job_duration !== currItem.job_duration) {
                  extraLog += `${extraLog ? ', ' : ''}Job Duration changes from "${prevDuration}" to "${currDuration}"`
                }

                log.updateClientTaskJob(
                  clientInfo.id,
                  prevItem,
                  currItem,
                  ['employee_id', 'status_id', 'status_name', 'service_id', 'service_name', 'job_duration', 'save_type'],
                  [
                    { key: 'job_date', label: 'Job Date' },
                    { key: 'notes', label: 'Details' },
                  ],
                  extraLog,
                  prefix
                )
                log.updateTaskJob(
                  taskInfo.id,
                  prevItem,
                  currItem,
                  ['employee_id', 'status_id', 'status_name', 'service_id', 'service_name', 'job_duration', 'save_type'],
                  [
                    { key: 'task', label: 'Objective' },
                    { key: 'due_date', label: 'Due Date' },
                    { key: 'notes', label: 'Details' },
                  ],
                  extraLog,
                  prefix,
                  'sc-task-job',
                  r1.id
                )
                log.updateJob(
                  r1.id,
                  prevItem,
                  currItem,
                  ['employee_id', 'status_id', 'status_name', 'service_id', 'service_name', 'job_duration', 'save_type'],
                  [
                    { key: 'task', label: 'Objective' },
                    { key: 'due_date', label: 'Due Date' },
                    { key: 'notes', label: 'Details' },
                  ],
                  extraLog,
                  prefix
                )

                this.handleSaveSuccessful()
                isSaved = true
              } else {
                this.handleSaveError(r1)
              }
            } else {
              values.client_id = clientInfo.id
              values.funder_id = funderInfo.id
              values.task_id = item.task_id

              const r2 = await taskJobService.add(values)

              if (r2 && r2.id) {
                const { id, ref_id } = r2

                const currEmployee = employeeList.find(e => e.id === values.employee_id)
                const currStatus = statusList.find(e => e.id === values.status_id)
                const currService = serviceList.find(e => e.id === values.service_id)

                const logText = `New Task Job for ${clientInfo.fullname} under Task "${taskInfo.id_numbering ? taskInfo.id_numbering.toUpperCase() : taskInfo.id_numbering}${taskInfo.title ? `: ${taskInfo.title}` : ''}" - Job Date as "${formatter.toShortDate(values.job_date)}", Job Duration as "${formatter.toDayHourText(values.job_duration)}", Support Coordinator as "${currEmployee && currEmployee.id ? currEmployee.fullname : ''}", Service as "${currService && currService.id ? currService.name : ''}", Task Status as "${currStatus && currStatus.id ? currStatus.name : ''}", Summary as "${values.summary}"${values.notes ? `, Details as "${values.notes}"` : ''}`

                log.addClientTaskJob(clientInfo.id, logText)
                log.addTaskJob(taskInfo.id, logText, 'sc-task-job', r2.id)
                log.addJob(r2.id, logText)

                this.handleSaveSuccessful()
                isSaved = true
              } else {
                this.handleSaveError(r2)
              }
            }
          } catch (e) {
            console.log('handle save job error', e)
            this.handleSaveError(e)
          }
        }

        this.setState({ loadingUpdate: false }, () => {
          if (isSaved) {
            this.onComplete()
          }
        })
      })
    })
  }

  handleCancelSuccessful = () => {
    notify.success('Cancelled successfully', 'Job canceleed successfully.')
  }

  handleCancelError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to cancel successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to cancel successfully', `${e.message}`)
    } else {
      notify.error('Unable to cancel successfully', 'Unable to cancel Job successfully. Please try again later.')
    }
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Job saved successfully.')
  }

  handleSaveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to save successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to save successfully', `${e.message}`)
    } else {
      notify.error('Unable to save successfully', 'Unable to save Job successfully. Please try again later.')
    }
  }

  handleCancel = async () => {
    const { item, loading, loadingUpdate } = this.state

    if (loading || loadingUpdate) {
      return
    }

    this.setState({ loadingUpdate: true }, async () => {
      let isUpdated = false
      try {
        const { isEdit, clientInfo, item, taskInfo } = this.state
        const values = {
          save_type: JobSaveType.CANCELLED,
          is_cancel: true
        }

        const r1 = await taskJobService.save(item.id, values)

        if (r1 && r1.id) {
          const { id, ref_id } = r1

          const logTaskText = `Task ${taskInfo.title ? `"${taskInfo.title.toUpperCase()}"` : taskInfo.id_numbering ? `"${taskInfo.id_numbering.toUpperCase()}"` : ''} - Job ${item.id_numbering ? `"${item.id_numbering.toUpperCase()}"` : ''} is cancelled.`

          log.cancelClientTaskJob(taskInfo.client_id, logTaskText)
          log.cancelTaskJob(taskInfo.id, logTaskText, 'sc-task-job', id)
          log.cancelJob(id, logTaskText)
          this.handleCancelSuccessful()

          isUpdated = true
        } else {
          this.handleCancelError(r1)
        }
      } catch (e) {
        console.log('handle cancel job error', e)
      }

      this.setState({ loadingUpdate: false }, () => {
        if (isUpdated) {
          this.onComplete()
        }
      })
    })
  }

  preHandleCancelJob = () => {
    const { loading, loadingUpdate } = this.state
    const { handleCancel } = this

    if (loading || loadingUpdate) {
      return
    }

    warning({
      title: `Cancel Job?`,
      content: (
        <div className='job-modal'>
          <div>Are you sure to cancel this job?</div>
          <div className='warning-msg'>THIS ACTION CANNOT UNDO! Press Confirm to continue, Cancel to return.</div>
        </div>
      ),
      cancelText: 'Cancel',
      okText: 'Confirm',
      onOk() {
        handleCancel()
      },
      onCancel () {
      }
    })
  }

  validateDateChange = (rule, value, callback) => {
    const { clientBudgetList, funderInfo } = this.state

    if (value) {
      const newBudget = checkDateInPlanPeriod(value, clientBudgetList, funderInfo)

      if (newBudget && newBudget.id) {
        callback()
      } else {
        callback(new Error('No valid plan available for selected job date.'))
      }
    } else {
      callback(new Error('Please select job date.'))
    }
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddJobModal))
