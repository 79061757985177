/* global google */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { cloneDeep, isEqual, debounce } from 'lodash'
import Moment from 'moment-timezone'

import { AustralianStates, Permissions } from '../../../constants'
import { authService, commonService, providerService, settingGeneralService } from '../../../services'
import { fetchingProviders, setRefreshActivityLog } from '../../../states/actions'
import { log, validator } from '../../../util'

// UI
import { CustomIdentifierList, Loading, Page, Panel } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

import ActivityLog from '../ActivityLog'
import ProviderFiles from '../File'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const { confirm } = Modal
const Option = Select.Option
const TabPane = Tabs.TabPane

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const TabList = [
  { tabId: 1, path: '/info' },
  // { tabId: 2, path: '/custom-identifier' },
  { tabId: 3, path: '/files' },
  { tabId: 4, path: '/logs' }
]

export class Provider extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      currentSubTab: '1',
      item: {},
      itemOri: {},
      loading: false,
      languageList: [],
      latitude: null,
      longitude: null,
      serviceList: [],
      showSave: false,
      showEdit: true,
      validateStatusName: undefined
      // loadingCheckName: false
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.debounceValidateNameDuplicate = debounce(this.debounceValidateNameDuplicate, 1000)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchProvider()
    }
    this.fetchSettings()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { history } = this.props
    const { currentTab, currentSubTab, item, loading, showSave, showEdit } = this.state
    const providerId = this.getId()
    const isInfoTab = currentTab === '1' && currentSubTab === '1'
    const isItem = item && item.id

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Provider (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.fullname} (View Only)` : showSave ? `${item.fullname} (Edit Mode)` : 'Provider'}>
            {/* {
              this.isEdit() && this.hasAccess('deleteProvider') ? showSave ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null : null} */}
            {
              showEdit && this.isEdit() && isItem && this.hasAccess(Permissions.PROVIDER.INFO.UPDATE) && isInfoTab
                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              ((!this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.CREATE)) ||
              (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.PROVIDER.INFO.UPDATE)))
              && isInfoTab
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  Save
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='funder-panel'>
            { this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.READ)
              ? <div className={`funder-panel-header ${item && item.active === false ? 'disabled' : ''}`}>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='funder-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={15}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-label'>Name</div>
                      <div className='funder-panel-header-value'>{item.fullname}<div className='funder-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                    <Col lg={15}>
                      <div className='funder-panel-header-label'>Services Provided</div>
                      <div className='funder-panel-header-value'>{item.preference && validator.isNotEmptyArray(item.preference.services_provider_list) ? item.preference.services_provider_list.join(', ') : 'N/A'}</div>
                    </Col>
                    {/* <Col lg={5}>
                      <div className='funder-panel-header-label'>ABN</div>
                      <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{formatter.toABNFormat(item.abn) || '-'}</div>
                    </Col> */}
                  </Row> }
              </div> : null
            }
            <div className='funder-panel-body'>
              <Tabs
                defaultActiveKey={currentTab}
                onChange={this.handleTabChange}
              >
                <TabPane tab='Provider Info' key='1'>
                  <Tabs
                    type='card'
                    defaultActiveKey={currentSubTab}
                    onChange={this.handleSubTabChange}
                  >
                    <TabPane tab='Info' key='1'>
                      { this.infoTab() }
                    </TabPane>
                    { this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.READ)
                      ? <TabPane tab='Custom Identifier' key='2'>
                        <CustomIdentifierList key={`cftab${currentTab}`} genreId={providerId} genre={'provider'} history={this.props.history} permission={Permissions.PROVIDER.INFO.UPDATE} />
                      </TabPane>
                      : null }
                  </Tabs>
                </TabPane>
                 { this.isEdit() && this.hasAccess(Permissions.PROVIDER.FILES.LIST)
                    ? <TabPane tab='Files' key='3'>
                      <ProviderFiles key={`pfrctab${currentTab}`} providerId={providerId} providerInfo={item} history={this.props.history} />
                    </TabPane>
                    : null }
                { this.isEdit() && this.hasAccess(Permissions.PROVIDER.INFO.READ)
                  ? <TabPane tab='Activity Log' key='4'>
                    <ActivityLog key={`actab${currentTab}`} providerId={providerId} history={this.props.history} />
                  </TabPane>
                  : null }
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { item, loading, languageList, serviceList, validateStatusName } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }
    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const longItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 18, md: 18, lg: 16 }
    }

    const specialFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 11 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    const isEdit = this.isEdit()

    return <div><Form>
      <Loading loading={loading} blur>
        <Panel title='Provider Information'>
          <Row>
            { isEdit
              ? <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                  {getFieldDecorator('acc_ref', {
                    initialValue: item.acc_ref
                  })(
                    <Input readOnly />
                  )}
                </FormItem>
              </Col>
              : null }
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active !== undefined ? item.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='ABN' hasFeedback extra='Enter ABN without spacing'>
                {getFieldDecorator('abn', {
                  initialValue: item.abn || null,
                  rules: [
                    { required: false, message: ' ' },
                    { validator: this.validateABN }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row> */}

          <FormItem {...formItemLayout} label='Name' hasFeedback validateStatus={validateStatusName}>
            {getFieldDecorator('fullname', {
              initialValue: item.fullname || null,
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter first name' },
                { whitespace: true, message: 'Please enter first name' }
              ]
            })(
              <Input addonAfter={<span style={{ padding: '0 4px' }} />} onChange={this.validateNameDuplicate} />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || null,
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || null
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={`state${states.value}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building || null,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || null,
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='General Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || null,
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Invoice Email' hasFeedback>
                {getFieldDecorator('sc_inv_email', {
                  initialValue: item.sc_inv_email || null,
                  rules: [
                    { validator: (r, v, c) => this.validateEmailMultiple(r, v, c, false) }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          {/* <FormItem {...formItemLayout} label='Service Suburbs' hasFeedback>
            {getFieldDecorator('preference.suburbs', {
              initialValue: item.preference && validator.isNotEmptyArray(item.preference.suburbs) ? item.preference.suburbs : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                { suburbList.map((sc) => {
                    return <Option key={`suburb${sc.id}`} value={sc.id}>{sc.name}</Option>
                  }) }
              </Select>
          )}
          </FormItem> */}
        </Panel>

        <Panel title='Services'>
          <FormItem {...formItemLayout} label='Language Other Than English' hasFeedback>
            {getFieldDecorator('preference.languages', {
              initialValue: item.preference && validator.isNotEmptyArray(item.preference.languages) ? item.preference.languages : []
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                { languageList.map((sc) => {
                    return <Option key={`lang${sc.id}`} value={sc.id}>{sc.name}</Option>
                  }) }
              </Select>
          )}
          </FormItem>

          <FormItem {...formItemLayout} label='Services Provided' hasFeedback>
            {getFieldDecorator('preference.services_provider', {
              initialValue: item.preference && validator.isNotEmptyArray(item.preference.services_provider) ? item.preference.services_provider : [],
              rules: [
                { required: true, message: 'Please select services provided.' }
              ]
            })(
              <Select
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                { serviceList.map((sc) => {
                    return <Option key={`svlist${sc.id}`} value={sc.id}>{sc.name}</Option>
                  }) }
              </Select>
          )}
          </FormItem>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || null
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Panel>

        <Panel title='Additional Information'>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Name' hasFeedback>
                {getFieldDecorator('after_contact_name', {
                  initialValue: item.after_contact_name || null
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Number' hasFeedback>
                {getFieldDecorator('after_contact_number', {
                  initialValue: item.after_contact_number || null
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Email' hasFeedback>
                {getFieldDecorator('after_contact_email', {
                  initialValue: item.after_contact_email || null
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

      </Loading>
    </Form>
    </div>
  }

  fetchProvider = async () => {
    if (!this.hasAccess(Permissions.PROVIDER.INFO.READ)) {
      return
    }

    try {
      const refId = this.getRefId()
      let id = null

      this.setState({ loading: true })
      const item = await providerService.getRef(refId)

      if (item && item.id) {
        id = item.id
        this.setState({ item, itemOri: cloneDeep(item), loading: false })
      } else {
        notify.error('Unable to load successfully', 'Unable to load provider successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load provider successfully. Please try again later.')
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'sc-provider-services' },
        { condition: '=', value: 'language' },
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    if (settings && settings.list && validator.isArray(settings.list)) {
      this.setState({
        settings: settings.list,
        languageList: settings.list.filter(item => item.identifier === 'language'),
        serviceList: settings.list.filter(item => item.identifier === 'sc-provider-services'),
      })
    }
  }

  handleSubTabChange = (index) => {
    this.setState({currentSubTab: index})
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/providers/${refId}${tab.path}`)
    }
  }

  handlePreDelete = () => {
    const { loading } = this.state
    if (loading) return

    const { handleDelete } = this
    const id = this.getId()

    confirm({
      title: 'Delete this provider?',
      content: 'THIS ACTION CANNOT UNDO! Press Ok to continue, Cancel to return',
      async onOk () {
        handleDelete(id)
      },
      onCancel () {
      }
    })
  }

  handleDelete = async (id) => {
    const { fetchingProviders, history } = this.props

    this.setState({ loading: true })

    const r = await providerService.remove(id)

    if (r && r.id) {
      notify.success('Deleted successfully', 'Provider deleted successfully.')
      log.deleteProvider(id, 'Provider is deleted.')
      window.location.replace('/providers')
      history.replace('/providers')
      fetchingProviders(true)
    } else {
      notify.error('Unable to delete successfully', 'Unable to delete provider successfully. Please try again later.')
    }

    this.setState({ loading: false })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSave = (e) => {
    e.preventDefault()
    const { loading } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    if (loading) {
      return
    }

    this.setState({ loading: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          const { fetchingProviders, history } = this.props
          const { item, latitude, longitude, loading } = this.state

          if (longitude && latitude) {
            values.longitude = longitude
            values.latitude = latitude
          }

          try {
            if (this.isEdit()) {
              const r1 = await providerService.save(item.id, values)

              if (r1 && r1.id) {
                //--- update provider log construction starts
                const prevItem = cloneDeep(this.state.itemOri)
                const currItem = cloneDeep(values)

                const prevPref = prevItem.preference
                const currPref = currItem.preference

                delete prevItem.preference
                delete currItem.preference

                const prefChanges = this.getPreferenceChangeWithName(
                  prevPref,
                  currPref,
                  [
                    'languages',
                    'services_provider'
                  ],
                  [
                    'Language Other Than English',
                    'Services Provided'
                  ])

                log.updateProvider(r1.id, prevItem, currItem, undefined, [{ key: 'email', label: 'General Email' }], prefChanges)
                //--- update provider log construction ends

                this.handleSaveSuccessful()
                fetchingProviders(true)
                await this.fetchProvider()
              } else {
                this.handleSaveError()
              }
            } else {
              const r2 = await providerService.add(values)


              if (r2 && r2.id) {
                const { id, ref_id, acc_ref } = r2
                log.addProvider(r2.id, `New provider ${values.fullname}`)
                this.setState({ item: { ...item, ...values, id, ref_id, acc_ref } })
                this.handleSaveSuccessful()
                window.location.replace(`/providers/${ref_id}/info`)
                fetchingProviders(true)
              } else {
                this.handleSaveError()
              }
            }

            this.props.setRefreshActivityLog(true)
          } catch (e) {
            this.handleSaveError()
          }
        }

        this.setState({ loading: false })
      })
    })


  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Provider saved successfully.')
  }

  handleSaveError = () => {
    notify.error('Unable to save successfully', 'Unable to save provider successfully. Please try again later.')
  }

  validateABN = (rule, value, callback) => {
    if (value === null || value === undefined || value === '') {
      callback()
    } else if (!validator.isDigit(value) || value.length !== 11) {
      callback(new Error('ABN is invalid in format'))
    } else {
      callback()
    }
  }

  validateEmailMultiple = (rule, value, callback, isRequired = false) => {
    const MSG_VALID_EMAIL = 'Please provide a valid email'
    const MSG_ENTER_EMAIL = 'Please enter email'

    if (value === null || value === undefined || value === '' || value.trim() === '') {
      if (isRequired) {
        callback(new Error(MSG_ENTER_EMAIL))
      } else {
        callback()
      }
    } else if (value && value.length > 0 ) {
      if (!validator.isEmailMultiple(value)) {
        callback(new Error(MSG_VALID_EMAIL))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  validateNameDuplicate = (e) => {
    e.persist()
    const value = e && e.target && e.target.value ? e.target.value : ''
    this.debounceValidateNameDuplicate(value)
  }

  debounceValidateNameDuplicate = (fullname) => {
    const { form } = this.props
    const { item, validateStatusName } = this.state

    if (this.isEdit() && fullname === item.fullname) {
      return
    }

    if (typeof fullname === 'string' && fullname.trim().length > 0 && validateStatusName !== 'validating') {
      this.setState({ validateStatusName: 'validating' }, async () => {
        const r = await commonService.checkDuplicate('provider', { fullname })

        if (r && r.invalid === true) {
          this.setState({ validateStatusName: 'error' }, () => {
            const w = window
            form.setFields({ fullname: { value: fullname, errors: [{ message: 'Duplicate record found.' }] } })
            confirm({
              title: 'Possible Duplicated Name',
              content: (
                <div style={{ color: 'rgb(238, 27, 27)' }}>
                  <p>There is a provider which has the same name with entered value.</p>
                  <p>Press "Go to" button to check the duplicated name or press "OK" to edit.</p>
                </div>
              ),
              okText: 'Go to Duplicated Provider Page',
              cancelText: 'OK',
              onOk() {
                w.open(`/providers/${r.ref_id}/info`, '_blank', 'noopener noreferrer')
              },
              onCancel() {
              }
            })
          })
        } else {
          this.setState({ validateStatusName: 'success' })
        }
      })
    }
  }

  getPreferenceChange = (prevPref, currPref, fields = [], fieldsName = []) => {
    let changeText = ''
    const { settings } = this.state

    if (fields.length === fieldsName.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const prev = prevPref[field]
        const curr = currPref[field]

        if (!isEqual(prev, curr)) {
          let prevText = []
          let currText = []
          if (validator.isArray(prev)) {
            for (let j = 0; j < prev.length; j++) {
              const s = settings.find(e => e.id === prev[j])
              if (s && s.id) {
                prevText.push(s.name)
              }
            }
          }

          if (validator.isArray(curr)) {
            for (let j = 0; j < curr.length; j++) {
              const s = settings.find(e => e.id === curr[j])
              if (s && s.id) {
                currText.push(s.name)
              }
            }
          }

          changeText += `${changeText ? ', ' : ''}${fieldsName[i]} from "${prevText.join(', ')}" to "${currText.join(', ')}"`
        }
      }
    }

    return changeText
  }

  getPreferenceChangeWithName (prevPref, currPref, fields = [], fieldsName = []) {
    let changeText = ''
    const { settings } = this.state

    if (fields.length === fieldsName.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const prevList = prevPref[`${field}_list`]
        const curr = currPref[field]
        const currArray = []

        if (validator.isArray(curr)) {
          for (let j = 0; j < curr.length; j++) {
            const s = settings.find(e => e.id === curr[j])
            if (s && s.id) {
              currArray.push(s)
            }
          }

          currArray.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            else return 0
          })
        }

        const prevText = validator.isArray(prevList) ? prevList.join(', ') : ''
        const currText = currArray.map(e => e.name).join(', ')

        if (prevText !== currText) {
          changeText += `${changeText ? ', ' : ''}${fieldsName[i]} from "${prevText}" to "${currText}"`
        }
      }
    }

    return changeText
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingProviders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Provider }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Provider))
